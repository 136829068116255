/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import {
  ADD_ADMIN_VENDOR_MANAGEMENT_URL,
  VIEW_ADMIN_VENDOR_MANAGEMENT_URL,
  EDIT_ADMIN_VENDOR_MANAGEMENT_URL,
  VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL,
  getOfferStatus,
  VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL,
  VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL,
  VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL,
  isEventLive,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import {
  addToCartList,
  addMultipleQauntityCart,
  removeMultipleQauntityCart,
  getBrandsWiseModelList,
  RemoveCartList,
  ClearFormSetFutios,
  setShowSuccessModal,
  setShowPaymentModal,
} from "../../redux/actions/adminActions";
import CommonModel from "../Common/CommonModel";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CommonewModel from "../Common/CommonewModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewEvent from "./ViewEvent";
import AddEditEvent from "./AddEditEvent";
import PaymentModal from "../Common/PaymentModal";

const VendorEventManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const {
    selfInfo,
    vendorEventList,
    device_id,
    categoryAllList,
    paymentModalData,
  } = useSelector((state) => state.adminReducers);
  console.log("paymentModalData", paymentModalData);
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPage") || 1
  );
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  console.log("vendorEventList", vendorEventList);
  const [GetFilteredSubStatus, setGetFilteredSubStatus] = useState("");
  const [GetFilteredStatus, setGetFilteredStatus] = useState("");

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // add new vendor
  const AddVendorFnct = () => {
    Navigate(VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL);
    Dispatch(getBrandsWiseModelList({}));
  };

  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);

  // get product list
  useEffect(() => {
    let paramRequest = {
      status: GetFilteredStatus,
      created_by: userType,
      sub_status: GetFilteredSubStatus,
      event_mode: "",
      limit: userLimit || 10,
      page: currentPage,
      search: userSearch.trim(),
      user_type: selfInfo?.user_type,
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "event_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [
    userSearch,
    userType,
    userLimit,
    currentPage,
    GetFilteredStatus,
    GetFilteredSubStatus,
    websocket,
  ]);

  const ResetFnct = () => {
    setUserSearch("");
    setUserType("all");
    setUserLimit(10);
    setCurrentPage(1);
    setGetFilteredSubStatus("");
    setGetFilteredStatus("");
  };

  // delete product
  const DeleteProductFnct = (data_) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      title: "Delete Product",
      subtitle: "Delete Product",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  const ProductActive = (data_, type, booleancheck) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      subtitle: "Active Event",
      description: `Are you sure you want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to this event <h6 class="text-small mt-3 fs-16px">${
        data_?.event_title
      }</h6>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      closeText: "No",
      icon: "vendorAI",
    });
  };
  const DeclinePayment = async (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Decline Event",
      subtitle: "Decline Event",
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };
  const showPaymentModalHandler = async (data) => {
    console.log("showPaymentModalHandler paymentModalData", data);
    Dispatch(
      setShowPaymentModal({
        type: "event",
        response: data,
        show: true,
      })
    );
  };

  return (
    <section className="dashboard_wrapper h-96 p-0">
      {paymentModalData?.show && paymentModalData?.data?.type === "event" && (
        <PaymentModal />
      )}

      <div className="filter_sectio">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <React.Fragment>
                {userSearch !== undefined && (
                  <div className="searchBoxwithbtn" style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control"
                      value={userSearch}
                      onChange={(e) => {
                        setUserSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                      placeholder={`Search by event title or event code`}
                      title="Search by event title or event code"
                      maxLength={100}
                    />
                    {userSearch === "" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-search"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                        <path d="M21 21l-6 -6"></path>
                      </svg>
                    ) : (
                      <i
                        className="bi bi-x-circle-fill searchclear"
                        onClick={() => ClearSearchFiled()}
                      ></i>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-8 d-flex justify-content-end gap-2">
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  style={{ width: "100%" }}
                  value={userType}
                  onChange={(e) => SelectUserTypeFnct(e)}
                >
                  <option value="" disabled selected>
                    Created By
                  </option>
                  <option value="all">All</option>
                  <option value="admin">Admin</option>
                  <option value="vendor">Self</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  value={GetFilteredStatus}
                  onChange={(e) => setGetFilteredStatus(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option value="" disabled selected>
                    Status
                  </option>
                  <option value="all">All</option>
                  <option value="approved">Approved</option>
                  <option value="declined">Declined</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  value={GetFilteredSubStatus}
                  onChange={(e) => setGetFilteredSubStatus(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option value="" disabled selected>
                    Sub Status
                  </option>
                  <option value="all">All</option>
                  <option value="live">Live</option>
                  <option value="expire">Expired</option>
                  <option value="upcoming">Upcoming</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={userLimit}
                  onChange={(e) => setUserLimit(parseInt(e.target.value))}
                >
                  <option value="" selected disabled>
                    Records
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>

            <div className="col-md-2" style={{ width: "auto" }}>
              <div className="form-group">
                <button
                  className="btn head_cmn_btn"
                  style={{ marginLeft: "0px" }}
                  onClick={() => AddVendorFnct()}
                >
                  + Add New Event
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 255px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              vendorEventList?.pagination?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - -68px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Event Image</th>
                  <th>Event Code</th>
                  <th>Event Title</th>

                  <th>Created By</th>
                  <th>Status</th>
                  <th>Sub Status</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {vendorEventList &&
                  vendorEventList?.list?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td id="list-avatar">
                            <AvatarGet
                              type="product"
                              url={
                                user?.files[0]?.url?.gif_url ||
                                user?.files[0]?.url?.file_url
                              }
                            />
                          </td>
                          <td>{user?.event_code}</td>
                          <td>{user?.event_title}</td>

                          <td>{user?.created_by}</td>
                          <td>
                            {user?.is_approved === true ? (
                              <span className="text-parrot">Approved</span>
                            ) : user?.is_approved === false ? (
                              <span className="text-danger">Declined</span>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td>
                            {getOfferStatus(
                              user?.event_start_time,
                              user?.event_end_time
                            )}
                          </td>

                          <td>
                            <CommonToggle
                              valueToggle={user?.is_active}
                              setValueToggle={() =>
                                ProductActive(
                                  user,
                                  "is_active",
                                  user?.is_active
                                )
                              }
                              name={""}
                            />
                          </td>
                          <td className="respoactionproduct">
                            {user?.is_paid ? (
                              <button className="px-5 actiontblebtn pt-1 pb-1 text-white bg-paid border-0 btn">
                                Paid
                              </button>
                            ) : (
                              <>
                                {user?.is_paid == false && (
                                  <button className="px-5 actiontblebtn text-white pt-1 pb-1 payment-declined payment-declined-text bg-paid border-0 btn">
                                    Declined
                                  </button>
                                )}
                                {user?.is_paid == null &&
                                  isEventLive(
                                    user?.event_start_time,
                                    user?.event_end_time
                                  ) === "Upcoming" && (
                                    <>
                                      <button
                                        className="px-3 actiontblebtn pt-1 pb-1 text-white bg-primary border-0 btn"
                                        onClick={() =>
                                          showPaymentModalHandler(user)
                                        }
                                      >
                                        Pay
                                      </button>
                                      <button
                                        className="px-3 actiontblebtn pt-1 pb-1 text-white bg-danger border-0 btn"
                                        onClick={() => DeclinePayment(user)}
                                      >
                                        Decline
                                      </button>
                                    </>
                                  )}
                              </>
                            )}
                            <button
                              type="button"
                              className="btn actiontblebtn view-btn"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "currentPage",
                                  currentPage
                                );
                                Navigate(
                                  `${VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL}/${user?.id}`
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </button>

                            {isEventLive(
                              user?.event_start_time,
                              user?.event_end_time
                            ) === "Upcoming" && (
                              <button
                                type="button"
                                className="btn actiontblebtn edit-btn"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "currentPage",
                                    currentPage
                                  );
                                  Navigate(
                                    `${VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL}/${user?.id}`
                                  );
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {vendorEventList && vendorEventList?.list?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={10}>
                      Events Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {vendorEventList?.pagination?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={vendorEventList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModalNew?.open && (
        <CommonewModel
          setCurrentPage={setCurrentPage}
          setShowModalNew={setShowModalNew}
          showModalNew={showModalNew}
        />
      )}
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default VendorEventManagement;
