/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import {
  ADD_ADMIN_VENDOR_MANAGEMENT_URL,
  VIEW_ADMIN_VENDOR_MANAGEMENT_URL,
  EDIT_ADMIN_VENDOR_MANAGEMENT_URL,
  ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_OFFER_MANAGEMENT_URL,
  dubaiStatesOptions,
  ADMIN_ADD_ADMIN_LIVE_MAP_MANAGEMENT_URL,
  ADMIN_LIVE_MAP_MANAGEMENT_URL,
  ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import {
  addToCartList,
  addMultipleQauntityCart,
  removeMultipleQauntityCart,
  getBrandsWiseModelList,
  RemoveCartList,
  ClearFormSetFutios,
} from "../../redux/actions/adminActions";
import CommonModel from "../Common/CommonModel";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CommonewModel from "../Common/CommonewModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewVendor from "./ViewVendor";
import AddEditVendor from "./AddEditVendor";
import moment from "moment";

const VendorManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, vendorList, categoryAllList } = useSelector(
    (state) => state.adminReducers
  );
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPage") || 1
  );
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  const [GetFilterCategoryIds, setGetFilterCategoryIds] = useState("");
  const [GetFilterByState, setGetFilterByState] = useState("");

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // add new vendor
  const AddVendorFnct = () => {
    Navigate(ADD_ADMIN_VENDOR_MANAGEMENT_URL);
  };

  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);

  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type,
        category_by: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);

  // get product list
  useEffect(() => {
    let paramRequest = {
      filter: userType,
      state_filter: GetFilterByState === "All" ? "" : GetFilterByState,
      category_filter:
        GetFilterCategoryIds === "All" ? "" : GetFilterCategoryIds,
      limit: userLimit || 10,
      page: currentPage,
      search: userSearch.trim(),
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "vendor_admin_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [
    userSearch,
    userType,
    userLimit,
    currentPage,
    GetFilterCategoryIds,
    GetFilterByState,
    websocket,
  ]);

  const ResetFnct = () => {
    setUserSearch("");
    setUserType("");
    setUserLimit(10);
    setCurrentPage(1);
    setGetFilterCategoryIds("");
    setGetFilterByState("");
  };

  const ProductActive = (data_, type, booleancheck) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      subtitle: "Active Product",
      description: `Are you sure you want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to this vendor <h6 class="text-small mt-3 fs-16px">${
        data_?.business_name
      }</h6>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      closeText: "No",
      icon: "vendorAI",
    });
  };

  return (
    <section className="dashboard_wrapper pt-0 border-0">
      <div className="filter_sectio">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <React.Fragment>
                {userSearch !== undefined && (
                  <div className="searchBoxwithbtn" style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control"
                      value={userSearch}
                      onChange={(e) => {
                        setUserSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                      placeholder={`Search by business name or vendor code`}
                      title="Search by business name or vendor code"
                      maxLength={100}
                    />
                    {userSearch === "" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-search"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                        <path d="M21 21l-6 -6"></path>
                      </svg>
                    ) : (
                      <i
                        className="bi bi-x-circle-fill searchclear"
                        onClick={() => ClearSearchFiled()}
                      ></i>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-8 d-flex justify-content-end gap-2">
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control status-icon activestatusselet"
                  style={{ width: "100%" }}
                  value={userType}
                  onChange={(e) => SelectUserTypeFnct(e)}
                >
                  <option value="" disabled selected>
                    Status
                  </option>
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  value={GetFilterByState}
                  onChange={(e) => setGetFilterByState(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled>
                    State
                  </option>
                  {dubaiStatesOptions?.map((item, index) => {
                    return (
                      <option key={index} value={item?.value}>
                        {item?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  value={GetFilterCategoryIds}
                  onChange={(e) => setGetFilterCategoryIds(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled>
                    Category
                  </option>
                  <option value="All">All</option>
                  {categoryAllList?.length > 0 &&
                    categoryAllList.map((elm, index) => {
                      return (
                        <option value={elm.id} key={index}>
                          {elm.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={userLimit}
                  onChange={(e) => setUserLimit(parseInt(e.target.value))}
                >
                  <option value="" disabled selected>
                    Records
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <button
                  className="btn head_cmn_btn"
                  style={{ marginLeft: "0px" }}
                  onClick={() => AddVendorFnct()}
                >
                  + Add New Vendor
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              vendorList?.pagination?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Business Name</th>
                  <th>Vendor Code</th>
                  <th>Contact Person </th>
                  <th>Contact Number</th>
                  <th>Email ID</th>
                  {/* <th>Coins</th>
                                <th>Ratings</th> */}
                  <th>Offer</th>
                  <th>Event</th>
                  <th>Created At</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {vendorList?.list?.length > 0 &&
                  vendorList?.list?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.business_name}</td>
                          <td>{user?.vendor_code}</td>
                          <td>{user?.owner_contact_person || "-"}</td>
                          <td>{user?.owner_contact_number || "-"}</td>
                          <td>{user?.owner_email_id || "-"}</td>

                          {/* <td>{user?.coins === null ? "-" : user?.coins}</td>
                                        <td>{user?.rating === null ? "-" : user?.rating}</td> */}
                          <td>
                            {user?.is_active ? (
                              <span
                                className="text-primary"
                                onClick={() =>
                                  Navigate(`${ADMIN_LIVE_MAP_MANAGEMENT_URL}`, {
                                    state: { userId: user?.id },
                                  })
                                }
                              >
                                + Add
                              </span>
                            ) : (
                              <span className="text-primary">+ Add</span>
                            )}
                          </td>
                          <td>
                            {user?.is_active ? (
                              <span
                                className="text-primary"
                                onClick={() =>
                                  Navigate(
                                    `${ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL}`,
                                    {
                                      state: { userId: user?.id },
                                    }
                                  )
                                }
                              >
                                + Add
                              </span>
                            ) : (
                              <span className="text-primary">+ Add</span>
                            )}
                          </td>
                          <td>{moment(user.created_at).format("LL")}</td>

                          {/* 
                                        <td>{ModelNameGet(user?.model) === null ? "-" : ModelNameGet(user?.model)}</td>
                                        <td>{BrandNameGet(user?.brand) === null ? "-" : BrandNameGet(user?.brand)}</td> */}
                          <td>
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-product"
                              )) && (
                              <CommonToggle
                                valueToggle={user?.is_active}
                                setValueToggle={() =>
                                  ProductActive(
                                    user,
                                    "is_active",
                                    user?.is_active
                                  )
                                }
                                name={""}
                              />
                            )}
                          </td>
                          <td className="respoactionproduct">
                            <button
                              type="button"
                              className="btn actiontblebtn view-btn"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "currentPage",
                                  currentPage
                                );
                                Navigate(
                                  `${VIEW_ADMIN_VENDOR_MANAGEMENT_URL}/${user?.id}`
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-product"
                              )) && (
                              <button
                                type="button"
                                className="btn actiontblebtn edit-btn"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "currentPage",
                                    currentPage
                                  );
                                  Navigate(
                                    `${EDIT_ADMIN_VENDOR_MANAGEMENT_URL}/${user?.id}`
                                  );
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {vendorList && vendorList?.list?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={10}>
                      Vendors Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {vendorList?.pagination?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={vendorList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModalNew?.open && (
        <CommonewModel
          setCurrentPage={setCurrentPage}
          setShowModalNew={setShowModalNew}
          showModalNew={showModalNew}
        />
      )}
    </section>
  );
};

export default VendorManagement;
