/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { Link, useNavigate } from "react-router-dom";
import {
  ADMIN_VIEW_USER_MANAGEMENT_URL,
  dubaiStatesOptions,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonewModel from "../Common/CommonewModel";

const UserManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, userList, categoryAllList } = useSelector(
    (state) => state.adminReducers
  );
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('currentPage') || 1);
  const [paginationLength, setPaginationLength] = useState("");
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  console.log("userList", userList?.data, "paginationLength", paginationLength);
  const [GetFilterCategoryIds, setGetFilterCategoryIds] = useState("");
  const [GetFilterByState, setGetFilterByState] = useState("");
  console.log(
    "GetFilterByState",
    GetFilterByState,
    "GetFilterCategoryIds",
    GetFilterCategoryIds
  );
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type,
        category_by: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    fetchData();
  }, [
    userSearch,
    userType,
    userLimit,
    GetFilterByState,
    GetFilterCategoryIds,
    currentPage,
    websocket,
  ]);

  // Get data from api
  const fetchData = async () => {
    let param = "";
    param = {
      transmit: "single",
      url: "user_listing",
      request: {
        limit: userLimit || 10,
        page: currentPage,
        search: userSearch.trim(),
        user_type: selfInfo?.user_type,
        state: GetFilterByState === "All" ? "" : GetFilterByState,
        category: GetFilterCategoryIds === "All" ? "" : GetFilterCategoryIds,
      },
    };
    wsSend_request(websocket, param);
    setPaginationLength(userList?.pagination);
  };
  // add new vendor

  const DeleteUserFnct = (data_) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      subtitle: "Delete User",
      description: `Are you sure you want to delete this user <h6 class="text-small mt-3 fs-16px">${data_?.username}</h6>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      closeText: "No",
      icon: "binIcon",
    });
  };
  const ProductActive = (data_, type, booleancheck) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      subtitle: "Active User",
      description: `Are you sure you want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to this user <h6 class="text-small mt-3 fs-16px">${
        data_?.username
      }</h6>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      closeText: "No",
      icon: "vendorAI",
    });
  };

  return (
    <section className="dashboard_wrapper pt-0">
      <div className="filter_sectio">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <React.Fragment>
                {userSearch !== undefined && (
                  <div className="searchBoxwithbtn" style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control"
                      value={userSearch}
                      onChange={(e) => {
                        setUserSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                      placeholder={`Search by users name or users code`}
                      title="Search by users name or users code"
                      maxLength={100}
                    />
                    {userSearch === "" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-search"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                        <path d="M21 21l-6 -6"></path>
                      </svg>
                    ) : (
                      <i
                        className="bi bi-x-circle-fill searchclear"
                        onClick={() => ClearSearchFiled()}
                      ></i>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-8 d-flex justify-content-end gap-2">
            <div className="col-md-auto">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  value={GetFilterByState}
                  onChange={(e) => setGetFilterByState(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled>
                    Select State
                  </option>
                  {dubaiStatesOptions?.map((item, index) => {
                    return (
                      <option key={index} value={item?.value}>
                        {item?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-auto">
              <div className="form-group">
                <select
                  className="form-control activestatusselet"
                  value={GetFilterCategoryIds}
                  onChange={(e) => setGetFilterCategoryIds(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled>
                    Select Category
                  </option>
                  <option value="All">All</option>
                  {categoryAllList?.length > 0 &&
                    categoryAllList?.map((elm, index) => {
                      return (
                        <option value={elm.id} key={index}>
                          {elm.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-auto">
              <div className="form-group">
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={userLimit}
                  onChange={(e) => setUserLimit(parseInt(e.target.value))}
                >
                  <option value="" disabled selected>
                    Records
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              paginationLength?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Photo</th>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>State</th>
                  <th>User Code</th>
                  <th>Coins</th>
                  <th>Badge</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList &&
                  userList?.data?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td id="user-avatar">
                            <AvatarGet
                              type="product"
                              url={user?.avatar?.thumbnail_url}
                            />
                          </td>
                          <td>{user?.username}</td>
                          <td>{user?.email}</td>
                          <td>{user?.state}</td>
                          <td>{user?.usercode}</td>
                          <td>{user?.coins}</td>
                          <td>{user?.badge?.level || "-"}</td>

                          <td>
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-product"
                              )) && (
                              <CommonToggle
                                valueToggle={user?.is_active}
                                setValueToggle={() =>
                                  ProductActive(
                                    user,
                                    "is_active",
                                    user?.is_active
                                  )
                                }
                                name={""}
                              />
                            )}
                          </td>
                          <td className="respoactionUsers">
                            <button
                              type="button"
                              className="btn actiontblebtn view-btn"
                              onClick={() =>
                                {
                                sessionStorage.setItem('currentPage', currentPage);
                                Navigate(
                                  `${ADMIN_VIEW_USER_MANAGEMENT_URL}/${user?.id}`
                                )
                              }
                              }
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "delete-user"
                              )) && (
                              <button
                                type="button"
                                className="btn actiontblebtn"
                                onClick={() => DeleteUserFnct(user)}
                              >
                                <i className="bi bi-trash3 rounded delete-btn"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {userList && userList.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      User Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {paginationLength?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
          />
        )}

        {showModalNew?.open && (
          <CommonewModel
            setCurrentPage={setCurrentPage}
            setShowModalNew={setShowModalNew}
            showModalNew={showModalNew}
          />
        )}
      </div>
    </section>
  );
};

export default UserManagement;
