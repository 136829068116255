/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  LoaderStartMain,
  setConfirmModal,
} from "../../redux/actions/adminActions";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import vendorMBBG from "../../assets/img/vendorMBBG.png";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import {
  GOOGLE_MAP_API_KEY,
  VENDOR_DASHBOARD_URL,
  bytesToKB,
  convertToUTCTime,
  countPlainTextOccurrences,
  dubaiStatesOptions,
  extractFilename,
  extractIdFromUrl,
  maxDescLength,
  maxSize,
  minDescLength,
} from "../Shared/constant";
import InputField from "../Common/InputField";
import {
  useJsApiLoader,
} from "@react-google-maps/api";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import MapPin from "../../assets/icons/MapPin.svg";
import closeIcon from "../../assets/img/closeIcon.png";
import BoxesGroup from "../VendorMangement/DragableMedia";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import { AddThumbnailModal } from "../VendorMangement/AddThumbnailModal";
import draftToHtml from "draftjs-to-html";
import TimePicker from "../Common/TimePicker/TimePicker.jsx";
import MediaViewer from "../Common/MediaViewer.jsx";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import Pin from "../../assets/icons/Pin.png";
import L from "leaflet"; // Import Leaflet library
import "leaflet/dist/leaflet.css";
import { MultiplePinMap } from "../Common/Map.jsx";
const pinIcon = L.icon({
  iconUrl: Pin,
  iconSize: [30, 30], // Size of the icon
  iconAnchor: [15, 30], // Position of the icon relative to marker's location
});

const VendorMyBusiness = () => {
  const { websocket } = useContext(WebSocketContext);

  const Dispatch = useDispatch();
  const Navigate = useNavigate();
  const {
    accessToken,
    dashboardCount,
    selfInfo,
    categoryList,
    subcategoryList,
  } = useSelector((state) => state.adminReducers);

  const [inputValue, setInputValue] = useState({
    business_name: selfInfo?.business_name,
    owner_contact_person: selfInfo?.owner_contact_number,
    business_email_id: selfInfo?.business_email_id,
    owner_email_id: selfInfo?.owner_email_id,
    owner_contact_number: selfInfo?.owner_contact_number,
    business_contact_number: selfInfo?.business_contact_number,
    business_contact_person: selfInfo?.business_contact_person,
    address: selfInfo?.address,
    location: `${selfInfo?.latitude},${selfInfo?.longitude}`,
  });
  const {
    business_name,
    owner_contact_person,
    business_contact_person,
    owner_contact_number,
    business_email_id,
    owner_email_id,
    business_contact_number,

    address,
    location,
  } = inputValue;
  // get product list
  const fileInputRef = useRef(null);
  const fileInputRefImage = useRef(null);

  const fileInputRefVideo = useRef(null);
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [charCount, setCharCount] = useState(0);

  // error field business_contact_number
  const [errorDesc, setErrorDesc] = useState("");

  const [errorbusiness_name, setErrorbusiness_name] = useState("");
  const [errorowner_contact_person, setErrorowner_contact_person] =
    useState("");
  const [errorowner_contact_number, setErrorowner_contact_number] =
    useState("");
  const [errorbusiness_email_id, setErrorbusiness_email_id] = useState("");
  const [errorbusiness_contact_number, setErrorbusiness_contact_number] =
    useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [errorLogo, setErrorLogo] = useState("");
  const [errorMedia, setErrorMedia] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [erroraddress, setErroraddress] = useState("");
  const [errorlocation, setErrorlocation] = useState("");
  const [errorTags, setErrorTags] = useState("");
  const [key, setKey] = useState("bd");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fileData, setFileData] = useState([]);

  const [errorbusiness_contact_person, setErrorbusiness_contact_person] =
    useState("");

  const [selectedTags, setSelectedTags] = useState([]);
  const categoryOptions = categoryList?.list
    ?.filter((elm) => elm?.is_active === true)
    .map((elm) => ({ value: elm?.id, label: elm?.name }));
  const subcategoryOptions = subcategoryList?.list
    .filter((elm) => elm?.is_active === true)
    .map((elm) => ({ value: elm?.id, label: elm?.name }));

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };
  const commaSeparatedCategories = selectedCategories
    .map((category) => category.value)
    .join(",");

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const removeCategory = (categoryToRemove) => {
    const updatedCategories = selectedCategories.filter(
      (category) => category.value !== categoryToRemove.value
    );
    setSelectedCategories(updatedCategories);
  };
  const removeTags = (TagsToRemove) => {
    const updatedTags = selectedTags.filter(
      (Tags) => Tags.value !== TagsToRemove.value
    );
    setSelectedTags(updatedTags);
  };
  const commaSeparatedTags = selectedTags.map((Tags) => Tags.value).join(",");
  const [selectedCountry, setSelectedCountry] = useState({
    value: selfInfo?.country,
    label: selfInfo?.country,
  });
  const [selectedState, setSelectedState] = useState({
    value: selfInfo?.state,
    label: selfInfo?.state,
  });

  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: null,
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type,
        category_by: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "subcategory_listing",
      request: {
        filter: null,
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    if (selfInfo && selfInfo.opening_hours) {
      const updatedStoreData = { ...storeData };

      selfInfo.opening_hours.forEach((day) => {
        const { day: openingDay, time } = day;
        const dayName =
          openingDay.charAt(0).toUpperCase() + openingDay.slice(1); // Capitalize the day name
        const openTime = time.opening_time;
        const closeTime = time.closing_time;

        // Update the storeData state based on the opening hours
        updatedStoreData[dayName] = {
          [`${dayName}_Active`]: true,
          open_time: openTime,
          close_time: closeTime,
        };
      });

      // Set the updated storeData state
      setStoreData(updatedStoreData);
    }
    if (selfInfo?.store_description) {
      setErrorDesc("");
      const defaultStoreDescriptionContent = selfInfo?.store_description;
      const storeDescriptionContentState = ContentState.createFromBlockArray(
        convertFromHTML(defaultStoreDescriptionContent)
      );
      const storeDescriptionEditorState = EditorState.createWithContent(
        storeDescriptionContentState
      );
      const characterCount = countPlainTextOccurrences(
        selfInfo?.store_description
      );
      setCharCount(characterCount);
      setQueEditorState(storeDescriptionEditorState);
    }

    if (selfInfo && selfInfo?.interest) {
      // Split the categories string into an array
      const productCategories = selfInfo?.interest.split(",");

      // Filter categoryList to find objects whose name matches the product categories
      const filteredCategories = categoryList?.list?.filter((category) =>
        productCategories.includes(category.name)
      );

      // Map the filtered categories to the required structure and update the state
      const mappedCategories = filteredCategories.map((category) => ({
        value: category.id,
        label: category.name,
      }));

      setSelectedCategories(mappedCategories);
    }
    if (selfInfo && selfInfo?.sub_interest) {
      // Split the categories string into an array
      const productCategories = selfInfo?.sub_interest.split(",");

      // Filter categoryList to find objects whose name matches the product categories
      const filteredCategories = subcategoryList?.list?.filter((category) =>
        productCategories.includes(category.name)
      );

      // Map the filtered categories to the required structure and update the state
      const mappedCategories = filteredCategories.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setSelectedTags(mappedCategories);
    }
    setAvatarImage({
      url: {
        file_url: selfInfo?.logo?.file_url,
      },
      size: selfInfo?.logo?.size,
      name: selfInfo?.logo?.original_name,
    });
    setFileData(selfInfo?.files);
  }, [selfInfo]);

  // Handle change when a country is selected
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");
  console.log("editorState", editorState);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: selfInfo?.latitude,
    lng: selfInfo?.longitude,
  });
  const inputRef = useRef();
  const libraries = ["places"];
  const handleClearInput = () => {
    inputRef.current.value = ""; // Set the input value to an empty string
    // inputRef.current="" && (inputRef.current.value = "");
    // console.log("first", inputRef.current.getPlaces());
    // inputRef.current.value = "";
    // console.log("second", inputRef.current.getPlaces());
  };
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      setSelectedLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };
  const handleMapClick = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });

    inputValue.user_lat = selectedLocation.lat;
    inputValue.user_lng = selectedLocation.lng;
    setInputValue((inputValue1) => ({ ...inputValue1, ...inputValue }));
    console.log("Selected Location:", selectedLocation, inputValue);
  };

  // useEffect(() => {
  //   if (selectedLocation?.lat && selectedLocation?.lng) {
  //     console.log("selectedLocation", selectedLocation);
  //     reverseGeocode(selectedLocation.lat, selectedLocation.lng);
  //   }
  // }, [selectedLocation]);

  const reverseGeocode = async (lat, lng) => {
    console.log("lat, lng", lat, lng);
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}&language=en&region=us`
    );

    if (!response.ok) {
      console.error("Failed to fetch address from the Geocoding API");
      return;
    }

    const inputData = await response.json();
    if (inputData.results && inputData.results.length > 0) {
      const address = inputData.results[0].formatted_address;
      const city =
        inputData.results[0].address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name || "";

      const postalCode =
        inputData.results[0].address_components.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "";

      const state =
        inputData.results[0].address_components.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name || "";

      const stateCode =
        inputData.results[0].address_components.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.short_name || "";

      setInputValue((previnputValue) => ({
        ...previnputValue,
        address: address,
        city: city,
        postal_code: postalCode,
        location: address,
        state: state,
        state_code: stateCode,
      }));
      setErroraddress("");

      console.log("City:", city);
      console.log("Postal Code:", postalCode);
    } else {
      console.error("No results found from the Geocoding API");
    }
  };
  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false, // Disable the map type control
  };
  const [showVideoModal, setShowVideoModal] = useState({
    show: false,
    to: "",
    id: "",
    parent_id: "",
  });
  // upload product image

  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files, files.name);

    formData?.append("to", selfInfo?.business_email_id  || selfInfo?.owner_email_id);

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("fileresponse", fileresponse, "formData", formData);

    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );
    }
    Dispatch(LoaderStartMain(false));
  };
  const UploadVideo = async (e) => {
    Dispatch(LoaderStartMain(true));

    setUploadFileLoader(true);
    const files = e.target.files[0];
    if (files && files.size > maxSize) {
      toast.error("File size exceeds 30MB limit.");
      e.target.value = null; // Clear the input field
      return;
    }
    const formData = new FormData();
    formData.append("file", files, files.name);
    formData?.append("to",selfInfo?.business_email_id  || selfInfo?.owner_email_id);

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("fileresponse", fileresponse, "formData", formData);

    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );

      setShowVideoModal((prev) => ({
        ...prev,
        show: true,
        to: formData?.to,
        id: selfInfo?.business_email_id  || selfInfo?.owner_email_id,
        parent_id: fileresponse[0]?.id,
      }));
    }
    Dispatch(LoaderStartMain(false));
  };
  const UploadLogo = async (e) => {
    Dispatch(LoaderStartMain(true));

    console.log("UploadLogo called");
    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", files, files.name);

    formData?.append("to", selfInfo?.owner_email_id);

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");
    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("UploadLogo fileresponse", fileresponse, "formData", formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse[0]);
      setAvatarImageId(fileresponse[0]?.id);
    }
    Dispatch(LoaderStartMain(false));
  };

  const handleFileChange = (e) => {
    const fileInput = fileInputRef.current;
    // if (fileInput.files.length > 0 && !AvatarImage) {
    UploadLogo(e);
    // }
  };
  const handleFileChangeImage = (e) => {
    const fileInput = fileInputRefImage.current;
    if (fileInput.files.length > 0) {
      UploadImage(e);
    }
  };
  const handleFileChangeVideo = (e) => {
    const fileInput = fileInputRefVideo.current;
    if (fileInput.files.length > 0) {
      UploadVideo(e);
    }
  };
  const [storeData, setStoreData] = useState({
    Monday: {
      Monday_Active: false,
      open_time: "",
      close_time: "",
    },
    Tuesday: {
      Tuesday_Active: false,
      open_time: "",
      close_time: "",
    },
    Wednesday: {
      Wednesday_Active: false,
      open_time: "",
      close_time: "",
    },
    Thursday: {
      Thursday_Active: false,
      open_time: "",
      close_time: "",
    },
    Friday: {
      Friday_Active: false,
      open_time: "",
      close_time: "",
    },
    Saturday: {
      Saturday_Active: false,
      open_time: "",
      close_time: "",
    },
    Sunday: {
      Sunday_Active: false,
      open_time: "",
      close_time: "",
    },
  });
  console.log("storeData", storeData);

  const outputSchedule = Object.entries(storeData)
    .filter(([day, data]) => data[`${day}_Active`])
    .map(([day, data]) => ({
      day: day.toLowerCase(),
      time: {
        opening_time: convertToUTCTime(data.open_time),
        closing_time: convertToUTCTime(data.close_time),
      },
    }));

  function changeHandler(event, day, name) {
    console.log("event, day", event, day);
    if (event && event.hour && event.minute) {
      // Construct the time string from hour and minute
      const timeString = `${event.hour}:${event.minute}`;

      // Update storeData using the provided name parameter
      setStoreData((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          [name]: timeString,
        },
      }));
    }
    if (event?.target) {
      const { name, value, checked, type } = event.target;

      setStoreData((prev) => {
        console.log("Updating storeData:", prev); // Log before update
        return {
          ...prev,
          [day]: {
            ...prev[day],
            [name]: type === "checkbox" ? checked : value,
          },
        };
      });
    }
  }
  const handleFormClick = () => {
    if (AvatarImage?.name) {
      console.log("AvatarImage", AvatarImage);
      toast.error("Only a single logo upload is allowed.");
    } else {
      fileInputRef.current.click();
    }
  };
  const handleFormClickImage = () => {
    if (fileData?.length < 20) {
      fileInputRefImage.current.click();
    } else {
      toast.error("Maximum 20 files can be upload");
    }
  };
  const handleFormClickVideo = () => {
    if (fileData?.length < 20) {
      fileInputRefVideo.current.click();
    } else {
      toast.error("Maximum 20 files can be upload");
    }
  };
  const removeMediaAPIHandler = async () => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(AvatarImage?.url?.file_url)
    );
    console.log("response", response);
    if (response?.status === 200) {
      setAvatarImage("");
      setAvatarImageId("");
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  const RemoveUploadImage = async () => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: (e) => removeMediaAPIHandler(e),
      })
    );
  };
  const checkIsValidFiles = () => {
    if (fileData?.length > 0 && AvatarImage?.url?.file_url) {
      return true;
    } else {
      if (fileData?.length == 0) {
        setErrorMedia("Please upload media");
      } else {
        setErrorMedia("");
      }
      if (!AvatarImage?.url?.file_url) {
        setErrorLogo("Please upload logo");
      } else {
        setErrorLogo("");
      }
      return false;
    }
  };
  const checkIsValid = () => {
    
    if (
      inputValue?.business_name &&
      inputValue?.address &&
      inputValue?.owner_contact_person &&
      inputValue?.owner_email_id &&
      inputValue?.owner_contact_number &&
      inputValue?.business_name &&
      commaSeparatedCategories &&
      commaSeparatedTags &&
      selectedCategories &&
      selectedTags &&
      errorState === "" &&
      errorCountry === "" &&
      erroraddress === "" &&
      errorlocation === "" &&
      errorCategory === "" &&
      errorTags === "" &&
      errorMedia === "" &&
      AvatarImage?.url?.file_url &&
      charCount > minDescLength &&
      charCount < maxDescLength
    ) {
      return true;
    } else {
      if (charCount < minDescLength) {
        setErrorDesc(
          `Please enter at least ${minDescLength} character description`
        );
      } else {
        setErrorDesc("");
      }
      if (charCount > maxDescLength && charCount > minDescLength) {
        setErrorDesc(
          `Please enter maximum ${maxDescLength} character description`
        );
      }
      if (selectedCategories?.length == 0) {
        setErrorCategory("Please select category");
      } else {
        setErrorCategory("");
      }
      if (selectedTags?.length == 0) {
        setErrorTags("Please select tags");
      } else {
        setErrorTags("");
      }

      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErroraddress,
      });

      return false;
    }
  };
  const [submitClick, setSubmitClick] = useState(false);
  const CreateVendor = () => {
    console.log(
      "submitClick",
      submitClick,
      checkIsValid(),
      checkIsValidFiles()
    );

    setSubmitClick(true);
    const isValid = checkIsValidFiles();
    console.log("isValid", isValid);
    if (checkIsValid() && isValid) {
      let param;
      param = {
        transmit: "single",
        url: "vendor_update",
        request: {
          logo: extractFilename(AvatarImage?.url?.file_url),
          business_name: inputValue?.business_name,
          business_email_id: inputValue?.business_email_id,
          business_contact_number: inputValue?.business_contact_number,
          business_contact_person: inputValue?.business_contact_person,
          address: inputValue?.location,
          location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
          country: selectedCountry?.value,
          state: inputValue?.state_code || selectedState?.value,
          interest: commaSeparatedCategories,
          sub_interest: commaSeparatedTags,
          store_description: StoreDesc?.length
            ? StoreDesc
            : selfInfo?.store_description,
          files: fileData?.map(({ sequence, name }) => ({ sequence, name })),
          opening_hours: outputSchedule?.length > 0 ? outputSchedule : [],
          vendor_id: selfInfo?.id,
          user_type: selfInfo?.user_type,
        },
      };

      wsSend_request(websocket, param);
      let requestPara = {
        transmit: "single",
        url: "user_get",
        request: {
          user_type: selfInfo?.user_type,
        },
      };
      wsSend_request(websocket, requestPara);
        Navigate(VENDOR_DASHBOARD_URL);
    } else {
      if (!isValid) {
        setKey("lp");
      } else {
        setKey("bd");
      }
    }
  };
  useEffect(() => {
    let requestPara = {
      transmit: "single",
      url: "user_get",
      request: {
        user_type: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, requestPara);
  }, [websocket]);
  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setQueEditorState(newEditorState);
      const contentState = newEditorState.getCurrentContent();
      const plainText = contentState.getPlainText("");
      const characterCount = plainText.length;
      setCharCount(characterCount);
      const contentAsRaw = convertToRaw(contentState);
      const htmlContent = draftToHtml(contentAsRaw);
      const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

      setStoreDesc(styledHtmlContent);
    // }
  };
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };
  // add new category
  const AddSubCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Sub Category",
      subtitle: "Add Sub Category",
      description: ``,
      modalType: "form",
      button: "Save",
    });
  };
  const [SameOpenAndClose, setSameOpenAndClose] = useState(false);
  const setInitialTimings = () => {
    if (SameOpenAndClose) {
      setStoreData({
        Monday: {
          Monday_Active: false,
          open_time: "",
          close_time: "",
        },
        Tuesday: {
          Tuesday_Active: false,
          open_time: "",
          close_time: "",
        },
        Wednesday: {
          Wednesday_Active: false,
          open_time: "",
          close_time: "",
        },
        Thursday: {
          Thursday_Active: false,
          open_time: "",
          close_time: "",
        },
        Friday: {
          Friday_Active: false,
          open_time: "",
          close_time: "",
        },
        Saturday: {
          Saturday_Active: false,
          open_time: "",
          close_time: "",
        },
        Sunday: {
          Sunday_Active: false,
          open_time: "",
          close_time: "",
        },
      });
    }
  };
  useEffect(() => {
    if (SameOpenAndClose) {
      // Set same open_time and close_time as per Monday for all days
      const { open_time, close_time } = storeData.Monday;
      const updatedStoreData = Object.keys(storeData).reduce((acc, day) => {
        acc[day] = {
          [`${day}_Active`]: true,
          open_time,
          close_time,
        };
        return acc;
      }, {});
      setStoreData(updatedStoreData);
    }
  }, [SameOpenAndClose]);
  useEffect(() => {
    if (key === "submit") {
      const btn = document.getElementById("controlled-tab-example-tab-submit");
      if (btn) {
        btn.classList.toggle("border-0");
      }
      CreateVendor();
    }
  }, [key]);
  return (
    <section className="dashboard_wrapper border-0">
      {showVideoModal?.show === true ? (
        <AddThumbnailModal
          data={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          setFileData={setFileData}
          fileData={fileData}
        />
      ) : (
        <div
          style={{ height: "calc(100vh - 100px)" }}
          className="ScrollbarsWrapper overflow-x-hidden"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-11 border ">
                <div className="col-md-12">
                  <img src={vendorMBBG} alt="" className="w-100" />
                  <div className="position-relative">
                    <img
                      src={selfInfo?.logo?.file_url}
                      className="rounded-circle position-absolute"
                      width={"118px"}
                      height={"118px"}
                      style={{ bottom: "-50px", left: "3%" }}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-12 ms-5" style={{ marginTop: "4rem" }}>
                  <small className="fs-14px fw-600">
                    Vendor Code - {selfInfo?.vendor_code}
                  </small>
                </div>
                <div className="col-md-12 mt-3 m-0 border p-3" id="my-business">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 d-flex justify-content-space-evenly"
                  >
                    <Tab eventKey="bd" title="Business Details">
                      <div className="row">
                        <div className="col-md-9">
                          <InputField
                            type="text"
                            value={business_name}
                            className={
                              errorbusiness_name !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter Business Name"
                            label="Business Name <span class='redmednstar'>*</span>"
                            name="business_name"
                            onChange={handleChange}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "business_name",
                                error: setErrorbusiness_name,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorbusiness_name}
                            maxLength={100}
                            minLength={1}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <InputField
                            type="text"
                            value={business_contact_person}
                            className={"form-control"}
                            placeholder="Enter Contact Person"
                            label="Business Contact Person"
                            name="business_contact_person"
                            onChange={handleChange}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorbusiness_contact_person}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "owner_contact_person",
                                error: setErrorbusiness_contact_person,
                              })
                            }
                            maxLength={100}
                            minLength={1}
                            required={true}
                          />
                        </div>
                        <div className="col-md-3">
                          <InputField
                            type="tel"
                            value={business_contact_number}
                            className={
                              errorbusiness_contact_number !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter Contact Number"
                            label="Business Contact Number <span class='redmednstar'>*</span>"
                            name="business_contact_number"
                            onChange={handleChange}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "owner_contact_number",
                                error: setErrorbusiness_contact_number,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorbusiness_contact_number}
                            required={true}
                            maxLength={16}
                            minLength={9}
                          />
                        </div>

                        <div className="col-md-3">
                          <InputField
                            type="email"
                            value={business_email_id}
                            className={
                              errorbusiness_email_id !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter email address"
                            label="Business Email Address"
                            name="business_email_id"
                            onChange={handleChange}
                            errormessage={errorbusiness_email_id}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "email",
                                error: setErrorbusiness_email_id,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            maxLength={100}
                            minLength={1}
                            required={true}
                          />
                        </div>

                        <div className="col-md-9 row">
                          <div className="col-md-12">
                            <label
                              htmlFor="toolbarClassName"
                              className="mb-2 fs-14px fw-600"
                            >
                              Store Description
                            </label>
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={onEditorChange}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              wrapperStyle={{
                                width: "100%",
                                border: "1px solid #ccc",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                              editorStyle={{
                                minHeight: "150px",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "4px",
                                fontFamily: "sans-serif",
                              }}
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                  "emoji",
                                  "history",
                                ],
                                inline: {
                                  inDropdown: false,
                                  options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "superscript",
                                    "subscript",
                                  ],
                                },
                                list: {
                                  inDropdown: false,
                                  options: ["unordered", "ordered"],
                                },
                                textAlign: {
                                  inDropdown: true,
                                },
                                link: { inDropdown: false },
                                history: { inDropdown: false },
                              }}
                            />
                            {errorDesc !== "" && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorDesc}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="ct" title="Category & Tags">
                      <div className="row">
                        <div className="col-md-12">
                          <h5>Business Category & Tags</h5>
                        </div>
                        <div className="col-md-12 d-flex gap-4 my-3">
                          <div className="col-md-6">
                            <h6 className="col-md-12">Categories</h6>

                            <div className="col-md-12 bg-f8 p-3 border rounded">
                              <div className="d-flex flex-column">
                                <div className="filedaddwraps justify-content-start mb-2">
                                  <Select
                                    value={selectedCategories}
                                    onChange={handleCategoryChange}
                                    options={categoryOptions}
                                    isSearchable
                                    isMulti
                                    placeholder="Select Categories"
                                  />
                                </div>
                                {errorCategory !== "" && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorCategory}
                                  </div>
                                )}

                                {selectedCategories.map((category, index) => (
                                  <div
                                    key={index}
                                    className="selected-category ms-2"
                                  >
                                    <i
                                      className="bi bi-dash-circle text-red"
                                      onClick={() => removeCategory(category)}
                                    ></i>
                                    <span className="ms-2">
                                      {" "}
                                      {category.label}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 pe-4">
                            <h6 className="col-md-12">Tags</h6>

                            <div className="col-md-12 bg-f8 p-3 border rounded">
                              <div className="d-flex flex-column">
                                <div className="filedaddwraps justify-content-start mb-2">
                                  <Select
                                    value={selectedTags}
                                    onChange={handleTagsChange}
                                    options={subcategoryOptions}
                                    isSearchable
                                    isMulti
                                    placeholder="Select Tags"
                                  />
                                  {/* <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                      <Tooltip
                                        id="button-tooltip-2"
                                        style={{ position: "fixed" }}
                                      >
                                        Add Tags
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn addbtn"
                                      onClick={(e) => AddSubCategoryFnct(e)}
                                    >
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  </OverlayTrigger> */}
                                </div>
                                {errorTags !== "" && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorTags}
                                  </div>
                                )}

                                {selectedTags.map((Tag, index) => (
                                  <div
                                    key={index}
                                    className="selected-Tag ms-2"
                                  >
                                    <i
                                      className="bi bi-dash-circle text-red"
                                      onClick={() => removeTags(Tag)}
                                    ></i>
                                    <span className="ms-2"> {Tag.label}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="add" title="Address">
                      <div className="col-md-12">
                        <h5>Business Address</h5>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-11">
                          <div className="col-md-12 d-flex gap-4 my-3">
                            <div className="col-md-6">
                              <div className="d-flex flex-column">
                                <label className="fs-14px mb-1">Country</label>
                                <div className="filedaddwraps justify-content-start mb-2">
                                  {/* <Select
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    options={vendorCountryOptions}
                                    placeholder="Select Country"
                                    required={true}
                                    isDisabled={true}
                                  /> */}
                                  <InputField
                                    type="text"
                                    className={"form-control"}
                                    value={selectedCountry?.value}
                                    errormessage={""}
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex flex-column">
                                <label className="fs-14px mb-1">State</label>
                                <div className="filedaddwraps justify-content-start mb-2">
                                  {/* <Select
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    options={dubaiStatesOptions}
                                    placeholder="Select State"
                                    required={true}
                                    isClearable={true}
                                    isDisabled={true}
                                  /> */}
                                  <InputField
                                    type="text"
                                    className={"form-control"}
                                    value={selectedState?.value}
                                    errormessage={""}
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <InputField
                            type="text"
                            value={address}
                            className={
                              erroraddress !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            label="Detail Address "
                            name="address"
                            onChange={handleChange}
                            onKeyDown={EmptySpaceFieldValid}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "Address",
                                error: setErroraddress,
                              })
                            }
                            errormessage={erroraddress}
                            readOnly={true}
                          />
                        </div>

                        <div className="col-md-12">
                          <MultiplePinMap
                            position={[selfInfo?.latitude, selfInfo?.longitude]}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="lp" title="Logo & Photos ">
                      <div className="">
                        <div className="row" style={{ margin: "0 auto" }}>
                          <div className="col-md-12">
                            <div className="col-md-12">
                              <h5 className="col-md-12 mb-3">
                                Business logo & Image,Video
                              </h5>
                              <h5 className="fs-18px">Add Business Logo</h5>
                            </div>
                            <div className="w-50 d-flex gap-5">
                              <form
                                className="file-upload-form border-dotted max-w-50 w-auto"
                                onClick={handleFormClick}
                              >
                                <label
                                  htmlFor="file"
                                  className="file-upload-label"
                                >
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="common-blue-bg upload-icon rounded me-4 mt-1 ms-2">
                                      <i className="bi bi-cloud-arrow-down-fill text-white py-2 px-3"></i>
                                    </div>
                                    <div className="d-flex flex-column mb-1">
                                      <div className="mt--1">
                                        <input
                                          ref={fileInputRef}
                                          id="fileInput"
                                          type="file"
                                          style={{ visibility: "hidden" }}
                                          className="form-control"
                                          onChange={(e) => handleFileChange(e)}
                                          name="logo" // Added the name attribute
                                          disabled={UploadFileLoader}
                                          accept=".png, .jpg, .jpeg"
                                        />
                                      </div>
                                      <div className="me-0">
                                        <h6 className="mb-1 my-0 me-1">
                                          Upload Files
                                        </h6>
                                        <small className="fs-12px secondary-text">
                                          JPG JPEG, PNG
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              </form>
                              {AvatarImage?.url?.file_url && (
                                <div className="col-auto border br-1rem d-flex position-relative">
                                  <img
                                    src={closeIcon}
                                    alt=""
                                    className="position-absolute top--5 end--7 w-1rem cursor-pointer"
                                    onClick={RemoveUploadImage}
                                  />
                                  <div className="ps-2 pe-4 py-2">
                                    <img
                                      className="w-3rem"
                                      src={AvatarImage?.url?.file_url}
                                      alt=""
                                    />
                                  </div>
                                  <div className="d-flex flex-column gap-0 me-2">
                                    <i className="mb-0">
                                      {AvatarImage?.original_name}
                                    </i>
                                    <p className="secondary-text">
                                      {bytesToKB(AvatarImage?.size)}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                            {errorLogo !== "" &&
                              submitClick &&
                              !AvatarImage?.url?.file_url && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorLogo}
                                </div>
                              )}
                          </div>
                          <div className="col-md-11 border-bottom mt-3 mx-5"></div>
                          <div className="col-md-12 mt-3">
                            <div className="col-md-5 d-none">
                              <div className="d-flex gap-2">
                                <div>
                                  <h5 className="col-md-12 mb-3 fs-18px">
                                    Upload Images
                                  </h5>
                                  <form
                                    className="file-upload-form col-md-8 border-dotted"
                                    onClick={handleFormClickImage}
                                  >
                                    <label
                                      htmlFor="file"
                                      className="file-upload-label"
                                    >
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="common-blue-bg upload-icon rounded me-4 mt-1 ms-2">
                                          <i className="bi bi-cloud-arrow-down-fill text-white"></i>
                                        </div>
                                        <div className="d-flex flex-column mb-1">
                                          <div className="mt--1">
                                            <input
                                              ref={fileInputRefImage}
                                              id="fileInputImage"
                                              type="file"
                                              style={{ visibility: "hidden" }}
                                              onChange={(e) => {
                                                handleFileChangeImage(e);
                                              }}
                                              accept=".png, .jpg, .jpeg"
                                            />
                                          </div>
                                          <div className="me-0">
                                            <h6 className="mb-1 my-0 me-1">
                                              Upload Files
                                            </h6>
                                            <small className="fs-12px secondary-text">
                                              JPG JPEG, PNG
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  </form>
                                </div>{" "}
                                <div>
                                  <h5 className="col-md-12 mb-3 fs-18px">
                                    Upload Videos
                                  </h5>
                                  <form
                                    className="file-upload-form col-md-9 border-dotted"
                                    onClick={handleFormClickVideo}
                                  >
                                    <label
                                      htmlFor="file"
                                      className="file-upload-label"
                                    >
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="common-blue-bg upload-icon rounded me-4 mt-1 ms-2">
                                          <i className="bi bi-cloud-arrow-down-fill text-white"></i>
                                        </div>
                                        <div className="d-flex flex-column mb-1">
                                          <div className="mt--1">
                                            <input
                                              ref={fileInputRefVideo}
                                              id="fileInputVideo"
                                              type="file"
                                              style={{ visibility: "hidden" }}
                                              onChange={(e) => {
                                                handleFileChangeVideo(e);
                                              }}
                                              accept=".mp4, .avi"
                                            />
                                          </div>
                                          <div className="me-0">
                                            <h6 className="mb-1 my-0 me-1">
                                              Upload Files
                                            </h6>
                                            <small className="fs-12px secondary-text">
                                              mp4,avi,mov,size 30MB
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  </form>
                                </div>
                              </div>
                              {errorMedia !== "" && submitClick && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorMedia}
                                </div>
                              )}
                            </div>
                            <div className="col-md-7"></div>
                            <div className="col-md-12 my-4">
                              <div
                                className="draggable-container d-flex flex-wrap gap-3"
                              >
                                {/* <BoxesGroup
                                  boxes={fileData}
                                  setBoxes={setFileData}
                                  accessToken={accessToken}
                                /> */}
                                {console.log("fileData", fileData)}
                                {fileData?.length > 0 &&
                                  fileData?.map((item, index) => {
                                    return (
                                      <div className="w-18 mx-1" key={index}>
                                        <MediaViewer
                                          media={item?.url?.file_url}
                                          thumbnail={item?.url?.thumbnail_url}
                                          minHeight={"150px"}
                                          minWidth={"150px"}
                                          maxHeight={"150px"}
                                          maxWidth={"150px"}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-22" hidden>
                            <div
                              className="form-group-btn"
                              onClick={() => CreateVendor()}
                              style={{ textAlign: "right" }}
                            >
                              <button className="btn addbtncmn">
                                {Location === "edit-product"
                                  ? "Save"
                                  : "Create"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="time" title="Time">
                      <div className="row">
                        <div
                          className="col-md-12"
                          id="open-close-add-edit-vendor"
                        >
                          <div className="col-md-12 d-flex align-items-baseline justify-content-between">
                            <h5 className="my-4 fs-18px">
                              Store Opening Time & Closing Time
                            </h5>
                            <div className="me-1 gap-2 d-flex align-items-center">
                              <label
                                htmlFor="SameOpenAndClose"
                                className="secondary-text"
                              >
                                Set same opening and closing time
                              </label>
                              <input
                                type="checkbox"
                                id="SameOpenAndClose"
                                name="SameOpenAndClose"
                                checked={SameOpenAndClose}
                                onChange={() => {
                                  setSameOpenAndClose(!SameOpenAndClose);
                                  setInitialTimings();
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 d-flex gap-3 flex-column">
                            <div className="day-input-container d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Monday"
                                name="Monday_Active"
                                checked={storeData?.Monday?.Monday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Monday")
                                }
                              />
                              {!storeData?.Monday?.Monday_Active ? (
                                <>
                                  <span className="col-md-2">Monday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Monday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>
                                  <input
                                    type="time"
                                    name="open_time"
                                    value={storeData?.Monday?.open_time}
                                    placeholder="Select Open Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Monday")
                                    }
                                    hidden
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Monday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Monday?.open_time}
                                    placeholder={storeData?.Monday?.open_time}
                                  />

                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type="time"
                                    name="close_time"
                                    value={storeData?.Monday?.close_time}
                                    placeholder="Select Close Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Monday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Monday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Monday?.close_time}
                                    placeholder={storeData?.Monday?.close_time}
                                  />
                                </>
                              )}
                            </div>

                            <div className="day-input-container d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Tuesday"
                                name="Tuesday_Active"
                                checked={storeData?.Tuesday?.Tuesday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Tuesday")
                                }
                              />
                              {!storeData?.Tuesday?.Tuesday_Active ? (
                                <>
                                  <span className="col-md-2">Tuesday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Tuesday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>
                                  <input
                                    hidden
                                    type="time"
                                    name="open_time"
                                    value={storeData?.Tuesday?.open_time}
                                    placeholder="Select Open Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Tuesday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Tuesday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Tuesday?.open_time}
                                    placeholder={storeData?.Tuesday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type="time"
                                    name="close_time"
                                    value={storeData?.Tuesday?.close_time}
                                    placeholder="Select Close Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Tuesday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Tuesday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Tuesday?.close_time}
                                    placeholder={storeData?.Tuesday?.close_time}
                                  />
                                </>
                              )}
                            </div>

                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Wednesday"
                                name="Wednesday_Active"
                                checked={storeData?.Wednesday?.Wednesday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Wednesday")
                                }
                              />
                              {!storeData?.Wednesday?.Wednesday_Active ? (
                                <>
                                  <span className="col-md-2">Wednesday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Wednesday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Wednesday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Wednesday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Wednesday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Wednesday?.open_time}
                                    placeholder={
                                      storeData?.Wednesday?.open_time
                                    }
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Wednesday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Wednesday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Wednesday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Wednesday?.close_time}
                                    placeholder={
                                      storeData?.Wednesday?.close_time
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Thursday"
                                name="Thursday_Active"
                                checked={storeData?.Thursday?.Thursday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Thursday")
                                }
                              />
                              {!storeData?.Thursday?.Thursday_Active ? (
                                <>
                                  <span className="col-md-2">Thursday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Thursday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Thursday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Thursday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Thursday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Thursday?.open_time}
                                    placeholder={storeData?.Thursday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Thursday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Thursday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Thursday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Thursday?.close_time}
                                    placeholder={
                                      storeData?.Thursday?.close_time
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Friday"
                                name="Friday_Active"
                                checked={storeData?.Friday?.Friday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Friday")
                                }
                              />
                              {!storeData?.Friday?.Friday_Active ? (
                                <>
                                  <span className="col-md-2">Friday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Friday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Friday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Friday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Friday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Friday?.open_time}
                                    placeholder={storeData?.Friday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Friday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Friday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Friday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Friday?.close_time}
                                    placeholder={storeData?.Friday?.close_time}
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Saturday"
                                name="Saturday_Active"
                                checked={storeData?.Saturday?.Saturday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Saturday")
                                }
                              />
                              {!storeData?.Saturday?.Saturday_Active ? (
                                <>
                                  <span className="col-md-2">Saturday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Saturday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Saturday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Saturday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Saturday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Saturday?.open_time}
                                    placeholder={storeData?.Saturday?.open_time}
                                  />

                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Saturday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Saturday")
                                    }
                                  />

                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Saturday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Saturday?.close_time}
                                    placeholder={
                                      storeData?.Saturday?.close_time
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Sunday"
                                name="Sunday_Active"
                                checked={storeData?.Sunday?.Sunday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Sunday")
                                }
                              />
                              {!storeData?.Sunday?.Sunday_Active ? (
                                <>
                                  <span className="col-md-2">Sunday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Sunday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Sunday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Sunday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Sunday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Sunday?.open_time}
                                    placeholder={storeData?.Sunday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Sunday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Sunday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Sunday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Sunday?.close_time}
                                    placeholder={storeData?.Sunday?.close_time}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="submit"
                      title={
                        <button className="btn bg-primary ms-5 rounded text-white">
                          Save Changes
                        </button>
                      }
                    >
                      {" "}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default VendorMyBusiness;
