import {
  parsePhoneNumber,
  getRegionCodeForCountryCode,
} from "awesome-phonenumber";

// empty space validation fucntion
const CheckValid = (value, states) => {
  const EmailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // eslint-disable-line
  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
  const nameRegex = /^[A-Za-z\s]+$/;
  const businessNameRegex = /^[a-zA-Z0-9\s.'&,()/-]+$/;
  // const mobileRegex = /^(?:\+\d{1,3})?\d{10}$/;
  // const mobileRegex = /^(?:\+971|0)?(?:50|52|54|55|56|58)\d{7}$/;
  var mobileRegex = /^(?:\+971|971)?\d{1,12}$/;
  if (states?.type === "email") {
    if (value !== "") {
      if (EmailRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (EmailRegex.test(value) === false) {
          return states?.error("Please enter valid email address");
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("Email address cannot be empty");
      } else if (EmailRegex.test(value) === false) {
        return states?.error("Please enter valid email address");
      } else {
        return states?.error("");
      }
    }
  }

  // password validation
  if (states?.type === "password") {
    if (value !== "") {
      if (PasswordRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (PasswordRegex.test(value) === false) {
          return states?.error(
            "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
          );
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("Password cannot be empty");
      } else if (PasswordRegex.test(value) === false) {
        return states?.error(
          "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
        );
      } else {
        return states?.error("");
      }
    }
  }

  // old password validation
  if (states?.type === "oldpassword") {
    if (value !== "") {
      if (PasswordRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (PasswordRegex.test(value) === false) {
          return states?.error(
            "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
          );
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("Old Password cannot be empty");
      } else if (PasswordRegex.test(value) === false) {
        return states?.error(
          "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
        );
      } else {
        return states?.error("");
      }
    }
  }

  // new password validation
  if (states?.type === "newpassword") {
    if (value !== "") {
      if (PasswordRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (PasswordRegex.test(value) === false) {
          return states?.error(
            "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
          );
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("New Password cannot be empty");
      } else if (PasswordRegex.test(value) === false) {
        return states?.error(
          "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
        );
      } else {
        return states?.error("");
      }
    }
  }

  // first name validation
  if (states?.type === "UserName") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("User name cannot be empty");
    }
  }
  if (states?.type === "Question") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Question cannot be empty");
    }
  }

  // last name validation
  if (states?.type === "Lname") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Last name cannot be empty");
    }
  }

  // first name validation
  if (states?.type === "SupplierName") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Supplier name cannot be empty");
    }
  }

  // Currency validation
  if (states?.type === "Currency") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Currency cannot be empty");
    }
  }

  // title validation
  if (states?.type === "Title") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Title cannot be empty");
    }
  }

  if (states?.type === "question") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Question cannot be empty");
    }
  }

  if (states?.type === "answer") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Answer cannot be empty");
    }
  }

  // WebSiteUrl validation
  if (states?.type === "WebSiteUrl") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("WebSite cannot be empty");
    }
  }

  if (states?.type === "business_name") {
    // Check if the value is not empty and matches the name regex
    console.log("nameRegex.test(value)", businessNameRegex.test(value));
    if (value.trim() !== "" && businessNameRegex.test(value)) {
      // If the value is valid, clear the error message
      return states?.error("");
    } else {
      // If the value is empty or does not match the regex, show an error message
      return states?.error("Please enter a valid business name");
    }
  }
  if (states?.type === "owner_contact_person") {
    if (value.trim() !== "" && nameRegex.test(value)) {
      return states?.error("");
    } else {
      return states?.error("Please enter a valid name");
    }
  }
  if (states?.type === "owner_contact_number") {
    if (value.trim() !== "" && mobileRegex.test(value)) {
      return states?.error("");
    } else {
      return states?.error("Please enter a valid number");
    }
  }

  // Address validation
  if (states?.type === "Address") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Address cannot be empty");
    }
  }
  if (states?.type === "BadgePoints") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Badge points cannot be empty");
    }
  }
  if (states?.type === "Message") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Message cannot be empty");
    }
  }
  if (states?.type === "BadgeLevel") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Badge Level cannot be empty");
    }
  }
  if (states?.type === "offer_title") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Offer Title cannot be empty");
    }
  }
  if (states?.type === "startDate") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please enter valid start date and time");
    }
  }
  if (states?.type === "endDate") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please enter valid end date and time");
    }
  }
  if (states?.type === "Avatar") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please upload an image");
    }
  }
  if (states?.type === "category") {
    console.log("category type in checkvalid", value);
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please select category");
    }
  }
  if (states?.type === "sub category") {
    if (value?.length === 0) {
      return states?.error("");
    } else {
      return states?.error("Please select tags");
    }
  }

  // phone validation
  if (states?.type === "phone") {
    const CountryStates = getRegionCodeForCountryCode(value?.phone_code);
    const CheckMobileNumber = parsePhoneNumber(value?.phone?.toString(), {
      regionCode: CountryStates,
    });
    if (value?.phone !== "") {
      if (CheckMobileNumber?.valid === true) {
        return states?.error("");
      } else {
        return states?.error(`Please enter valid region mobile number`);
      }
    } else {
      if (value?.phone === "") {
        return states?.error("Mobile no cannot be empty");
      } else if (CheckMobileNumber?.valid === false) {
        return states?.error("please enter valid region mobile number");
      } else {
        return states?.error("");
      }
    }
  }

  // Product Name validation
  if (states?.type === "ProductName") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Product name cannot be empty");
    }
  }

  // Product Name validation
  if (states?.type === "Model") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Model cannot be empty");
    }
  }

  // Brand validation
  if (states?.type === "Brand") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Brand cannot be empty");
    }
  }

  // Select brand validation
  if (states?.type === "Select Brand") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please select brand");
    }
  }

  // Unit const price validation
  if (states?.type === "Unitconstprice") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Unit cost price cannot be empty");
    }
  }

  // Unit const price validation
  if (states?.type === "Description") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Description cannot be empty");
    }
  }

  // Supplier name validation
  if (states?.type === "SupplierName") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Supplier name cannot be empty");
    }
  }

  // Supplier name validation
  if (states?.type === "SupplierSelect") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please select supplier");
    }
  }

  // permission validation
  if (states?.type === "permission") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please select permisssion");
    }
  }

  // Earning validation
  if (states?.type === "EarningReason") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Reason cannot be empty");
    }
  }
  if (states?.type === "EarningCoin") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Coin cannot be empty");
    }
  }

  // model name validation
  if (states?.type === "Model Name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Model name cannot be empty");
    }
  }

  // Company name validation
  if (states?.type === "CompanyName") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Company name cannot be empty");
    }
  }

  // VATRegistrationNo name validation
  if (states?.type === "VATRegistrationNo") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("VAT registration no cannot be empty");
    }
  }

  // RegistrationNo name validation
  if (states?.type === "RegistrationNo") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Registration no cannot be empty");
    }
  }

  // Amount validation
  if (states?.type === "Amount") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Amount cannot be empty");
    }
  }

  // InCoterm validation
  if (states?.type === "InCoterm") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("In-Coterm cannot be empty");
    }
  }

  // Quantity validation
  if (states?.type === "Quantity") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Quantity cannot be empty");
    }
  }

  // ItemNumber validation
  if (states?.type === "ItemNumber") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Item number cannot be empty");
    }
  }

  // PI number validation
  if (states?.type === "PInumber") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("PI-number cannot be empty");
    }
  }

  // HSCode validation
  if (states?.type === "HSCode") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("HS-code cannot be empty");
    }
  }

  // PcCode validation
  if (states?.type === "PcCode") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("PcCode cannot be empty");
    }
  }

  // setting validation for earing coin
  if (states?.type === "EarningReason") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Reason cannot be empty");
    }
  }
  if (states?.type === "EarningCoin") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Coins cannot be empty");
    }
  }

  // Category Name validation
  if (states?.type === "Category Name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Category Name cannot be empty");
    }
  }
  if (states?.type === "Sub Category Name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Sub Category Name cannot be empty");
    }
  }
  if (states?.type === "Select Category") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please select category");
    }
  }
  // setting name validation
  if (states?.type === "Setting Name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Field name cannot be empty");
    }
  }
};

export default CheckValid;
