/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DeleteUserAPI from "../../Api/DeleteUserAPI";
import CommonLogout from "./CommonLogout";
import {
  DeleteUser,
  LoaderStartMain,
  editUserList,
  setConfirmModal,
} from "../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateUserAPI from "../../Api/UpdateUserAPI";
import BtnLoader from "./BtnLoader";
import InputField from "./InputField";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import AddEditSupplier from "../Supplier/AddEditSupplier";
import fileIcon from "../../assets/img/fileIcon.png";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import MediaViewer from "./MediaViewer";
import { extractFilename, extractIdFromUrl } from "../Shared/constant";
import closeIcon from "../../assets/img/closeIcon.png";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";

const CommonModel = (props) => {
  const { websocket } = useContext(WebSocketContext);
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState({
    EarningCoin: "",
    EarningReason: "",
    BadgeLevel: "",
    BadgePoints: "",
    ModelName: "",
    CategoryName: "",
    SubCategoryName: "",
    ReviewMessage: "",
    SettingName: "",
    SupportEnqTitle: "",
  });
  const {
    EarningCoin,
    EarningReason,
    BadgeLevel,
    BadgePoints,
    ModelName,
    CategoryName,
    SubCategoryName,
    ReviewMessage,
    SettingName,
    SupportEnqTitle,
  } = inputValue;
  const Dispatch = useDispatch();
  const { showModal, setShowModal, setCurrentPage, setUserSearch, fromUrl } =
    props;
  console.log("showModal", showModal);
  const {
    accessToken,
    brandlistall,
    device_id,
    SelectedCurrentBrandID,
    categoryList,
    selfInfo,
    firebaseData,
  } = useSelector((state) => state.adminReducers);
  const [selectBrand, setSelectBrand] = useState("");
  const [ParentCategory, setParentCategory] = useState("");
  console.log("ParentCategory", ParentCategory);
  // error message
  const [errorEarningReason, setErrorEarningReason] = useState("");
  const [errorBadgeLevel, setErrorBadgeLevel] = useState("");
  const [errorBadgePoints, setErrorBadgePoints] = useState("");
  console.log(
    "errorBadgeLevel",
    errorBadgeLevel,
    "errorBadgePoints",
    errorBadgePoints
  );

  const [errorEarningCoin, setErrorEarningCoin] = useState("");

  const [errorModelName, setErrorModelName] = useState("");
  const [errorSelectBrand, setErrorSelectBrand] = useState("");
  const [errorCategoryName, setErrorCategoryName] = useState("");
  const [errorSubCategoryName, setErrorSubCategoryName] = useState("");
  const [errorReviewMessage, setErrorReviewMessage] = useState("");
  const [errorParentCategory, setErrorParentCategory] = useState("");
  const [errorSettingName, setErrorSettingName] = useState("");
  const [errorSupportEnqTitle, setErrorSupportEnqTitle] = useState("");
  const [errorDesc, setErrorDesc] = useState("");

  const selectedCategory = categoryList?.list?.find(
    (item) => item?.name === ParentCategory
  );
  const selectedCategoryId = selectedCategory?.id;

  // close modal
  const handleClose = () => {
    setShowModal({ ...showModal, open: false });
  };
  const [AvatarImage, setAvatarImage] = useState("");
  const removeMediaAPIHandler = async () => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(AvatarImage?.name)
    );
    console.log("response", response);
    if (response?.status === 200) {
      setAvatarImage("");
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  const RemoveUploadImage = async () => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: (e) => removeMediaAPIHandler(e),
      })
    );
  };
  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", files, files.name);
    formData?.append("to", selfInfo?.id);

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("fileresponse", fileresponse, "formData", formData);

    if (fileresponse) {
      setAvatarImage(fileresponse[0]);
    }
    Dispatch(LoaderStartMain(false));
  };
  // Textfield common onchange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getAllUserCategories = () => {
    const param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type,
        category_by: "user",
      },
    };
    wsSend_request(websocket, param);
  };
  const getAllCategories = () => {
    const param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type,
        category_by: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
    const existingCategory = categoryList?.list?.find(
      (item) => item?.id === showModal?.Data?.category_id
    );
    console.log("existingCategory", existingCategory);
    setParentCategory(existingCategory?.name);
  };

  useEffect(() => {
    if (showModal?.subtitle === "Edit Earning") {
      setInputValue({
        ...inputValue,
        EarningReason: showModal?.Data?.reason,
        EarningCoin: showModal?.Data?.value,
      });
    } else if (showModal?.subtitle === "Edit Badge") {
      setInputValue({
        ...inputValue,
        BadgePoints: showModal?.Data?.points,
        BadgeLevel: showModal?.Data?.level,
      });
      if (showModal?.Data?.logo?.file_url) {
        setAvatarImage({
          name: extractFilename(showModal?.Data?.logo?.file_url),
          url: showModal?.Data?.logo?.file_url,
        });
      }
    } else if (showModal?.subtitle === "Edit Model") {
      setInputValue({ ...inputValue, ModelName: showModal?.Data?.name });
      setSelectBrand(showModal?.Data?.brand_id);
    } else if (showModal?.subtitle === "Edit Category") {
      setInputValue({ ...inputValue, CategoryName: showModal?.Data?.name });
    } else if (showModal?.subtitle === "Edit Sub Category") {
      setInputValue({ ...inputValue, SubCategoryName: showModal?.Data?.name });
      getAllCategories();
    } else if (showModal?.subtitle === "Edit Field") {
      setInputValue({ ...inputValue, SettingName: showModal?.Data?.name });
    } else if (showModal?.subtitle === "Delete Image") {
      setInputValue({
        ...inputValue,
        ReviewMessage: `I would like to request the deletion of a photo with the following name: ${showModal?.Data?.name}.`,
      });
    } else if (showModal?.subtitle === "Delete Review") {
      setInputValue({
        ...inputValue,
        ReviewMessage: `I would like to request the deletion of a review with the following review ID: ${showModal?.Data?.id}.`,
      });
    } else if (
      showModal?.subtitle === "Share Coin" ||
      showModal?.subtitle === "Share Payment"
    ) {
      setInputValue({
        ...inputValue,
        ReviewMessage: `I would like to raise a ticket for the following transaction: ${
          showModal?.Data?.transaction_id || showModal?.Data?.transactions_id
        }.`,
      });
    }
  }, [showModal]);

  useEffect(() => {
    if (SelectedCurrentBrandID !== "" || SelectedCurrentBrandID !== undefined) {
      setSelectBrand(SelectedCurrentBrandID);
      CheckValid(SelectedCurrentBrandID, {
        type: "Select Brand",
        error: setErrorSelectBrand,
      });
    }
  }, [SelectedCurrentBrandID]);

  // select brand
  const selectBrandChange = (e) => {
    if (e.target.value) {
      setSelectBrand(e.target.value);
      CheckValid(e.target.value, {
        type: "Select Brand",
        error: setErrorSelectBrand,
      });
    } else {
      setSelectBrand("");
      CheckValid(e.target.value, {
        type: "Select Brand",
        error: setErrorSelectBrand,
      });
    }
  };

  const CommonFunction = async () => {
    if (showModal?.subtitle === "Delete User") {
      setLoader(true);
      const response = await DeleteUserAPI(accessToken, showModal?.Data?.id);
      if (response?.status === 200) {
        setTimeout(() => {
          setLoader(false);
          toast.success(response.msg);
          Dispatch(DeleteUser(response.data));
          setUserSearch("");
          handleClose();
        }, 100);
      } else if (response?.detail === "Token expired.") {
        setLoader(false);
        CommonLogout(accessToken, Dispatch);
        toast.error(response?.error || response?.data?.error);
      } else {
        setLoader(false);
        toast.error(response?.error || response?.data?.error);
      }
    } else if (showModal?.subtitle === "active User") {
      let param = {
        transmit: "single",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "admin User") {
      let param = {
        transmit: "single",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_admin: showModal?.Data?.is_admin ? 0 : 1,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "staff User") {
      let param = {
        transmit: "single",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_staff: showModal?.Data?.is_staff ? 0 : 1,
          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "Add Earnings") {
      if (EarningReason.trim() !== "") {
        let param = {
          transmit: "single",
          url: "coinearning_add",
          request: {
            reason: EarningReason,
            value: EarningCoin,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        CommonListUpdate("coinearning_listing");
        handleClose();
      } else {
        CheckValid(EarningReason, {
          type: "EarningReason",
          error: setErrorEarningReason,
        });
        CheckValid(EarningCoin, {
          type: "EarningCoin",
          error: setErrorEarningReason,
        });
      }
    } else if (showModal?.subtitle === "Edit Earning") {
      if (EarningReason?.trim() && EarningCoin) {
        let param = {
          transmit: "single",
          url: "coinearning_update",
          request: {
            value: EarningCoin,
            earning_id: showModal?.Data?.id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("coinearning_listing");
        handleClose();
      }
    } else if (showModal?.subtitle === "Create Badge") {
      if (BadgeLevel.trim() !== "") {
        let param = {
          transmit: "single",
          url: "add_product_brand",
          request: {
            BadgeLevel: BadgeLevel,
            BadgePoints: BadgePoints,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_brand_list");
        handleClose();
      } else {
        CheckValid(BadgeLevel, {
          type: "BadgeLevel",
          error: setErrorBadgeLevel,
        });
        CheckValid(BadgePoints, {
          type: "BadgePoints",
          error: setErrorBadgePoints,
        });
      }
    } else if (showModal?.subtitle === "Add Badge") {
      if (BadgeLevel.trim() !== "" && BadgePoints) {
        let param = {
          transmit: "single",
          url: "badge_add",
          request: {
            level: BadgeLevel,
            points: BadgePoints,
            logo: AvatarImage?.name,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("badge_listing");
        handleClose();
      } else {
        CheckValid(BadgeLevel, {
          type: "BadgeLevel",
          error: setErrorBadgeLevel,
        });
        CheckValid(BadgePoints, {
          type: "BadgePoints",
          error: setErrorBadgePoints,
        });
      }
    } else if (showModal?.subtitle === "Edit Badge") {
      if (BadgeLevel.trim() !== "") {
        let param = {
          transmit: "single",
          url: "badge_update",
          request: {
            level: BadgeLevel,
            points: BadgePoints,
            logo: AvatarImage?.name,
            badge_id: showModal?.Data?.id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("badge_listing");
        handleClose();
      } else {
        CheckValid(BadgeLevel, {
          type: "BadgeLevel",
          error: setErrorBadgeLevel,
        });
        CheckValid(BadgePoints, {
          type: "BadgePoints",
          error: setErrorBadgePoints,
        });
      }
    } else if (showModal?.subtitle === "Edit Earning") {
      if (BadgeLevel?.trim() && BadgePoints) {
        let param = {
          transmit: "single",
          url: "coinearning_update",
          request: {
            BadgePoints: BadgePoints,
            BadgeLevel: BadgeLevel,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_brand_list");
        handleClose();
      } else {
        CheckValid(EarningReason?.trim(), {
          type: "Brand Name",
          error: setErrorEarningReason,
        });
        CheckValid(EarningCoin, {
          type: "EarningCoin",
          error: setErrorEarningReason,
        });
      }
    } else if (showModal?.subtitle === "Delete Badge") {
      let param = {
        transmit: "single",
        url: "badge_delete",
        request: {
          badge_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("badge_listing");
      handleClose();
    } else if (showModal?.subtitle === "Create Model") {
      if (ModelName !== "" && selectBrand !== "") {
        let param = {
          transmit: "single",
          url: "add_product_model",
          request: {
            ba_id: selectBrand,
            name: ModelName,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else {
        CheckValid(ModelName, { type: "Model Name", error: setErrorModelName });
        CheckValid(selectBrand, {
          type: "Select Brand",
          error: setErrorSelectBrand,
        });
      }
    } else if (showModal?.subtitle === "Active Model") {
      let param = {
        transmit: "single",
        url: "update_product_model",
        request: {
          model_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("product_model_list");
      handleClose();
    } else if (showModal?.subtitle === "Edit Model") {
      if (
        showModal?.Data?.name !== ModelName &&
        showModal?.Data?.brand_id !== selectBrand
      ) {
        let param = {
          transmit: "single",
          url: "update_product_model",
          request: {
            name: ModelName,
            model_id: showModal?.Data?.id,
            brand_id: selectBrand,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else if (showModal?.Data?.name !== ModelName) {
        let param = {
          transmit: "single",
          url: "update_product_model",
          request: {
            name: ModelName,
            model_id: showModal?.Data?.id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else if (showModal?.Data?.brand_id !== selectBrand) {
        let param = {
          transmit: "single",
          url: "update_product_model",
          request: {
            brand_id: selectBrand,
            model_id: showModal?.Data?.id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_model_list");
        handleClose();
      } else {
        toast.error(
          "If you want to edit model then change model name or brand name"
        );
      }
    } else if (showModal?.subtitle === "Delete Model") {
      let param = {
        transmit: "single",
        url: "delete_product_model",
        request: {
          model_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("product_model_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Supplier") {
      let param = {
        transmit: "single",
        url: "delete_supplier",
        request: {
          supplier_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("supplier_list");
      handleClose();
    } else if (showModal?.subtitle === "Active Supplier") {
      let param = {
        transmit: "single",
        url: "update_supplier",
        request: {
          supplier_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          name: showModal?.Data?.name,
          email: showModal?.Data?.email,
          phone_code: showModal?.Data?.phone_code,
          title: showModal?.Data?.title,
          website: showModal?.Data?.website,
          phone: showModal?.Data?.phone,
          company: showModal?.Data?.company,
          address: showModal?.Data?.address,
          vat_registration_no: showModal?.Data?.vat_registration_no,
          registration_no: showModal?.Data?.registration_no,
          has_contact: showModal?.Data?.has_contact,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("supplier_list");
      handleClose();
    } else if (showModal?.subtitle === "Active Product") {
      let param = {
        transmit: "single",
        url: "update_product",
        request: {
          product_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          pi_no: showModal?.Data?.pi_no,
          avatar:
            showModal?.Data?.avatar === "" ? null : showModal?.Data?.avatar,
          name: showModal?.Data?.name,
          brand: showModal?.Data?.brand,
          model: showModal?.Data?.model,
          quantity: showModal?.Data?.quantity,
          supplier: showModal?.Data?.supplier,
          unit_cost_price: showModal?.Data?.unit_cost_price,
          incoterm: showModal?.Data?.incoterm,
          hs_code: showModal?.Data?.hs_code,
          dimensions: showModal?.Data?.dimensions,
          currency: showModal?.Data?.currency,
          floor: showModal?.Data?.floor,
          ps: showModal?.Data?.ps,
          date: showModal?.Data?.date,
          client_code: showModal?.Data?.client_code,
          pc_code: showModal?.Data?.pc_code,
          description_french: showModal?.Data?.description_french,
          description_english: showModal?.Data?.description_english,
          department: showModal?.Data?.department,
          unit: showModal?.Data?.unit,
          ucp_usd: showModal?.Data?.ucp_usd,
          tcp_usd: showModal?.Data?.tcp_usd,
          usp_usd: showModal?.Data?.usp_usd,
          tsp_usd: showModal?.Data?.tsp_usd,
          lot_number: showModal?.Data?.lot_number,
          brochure: showModal?.Data?.brochure,
          remarks: showModal?.Data?.remarks,
          country_of_origin: showModal?.Data?.country_of_origin,
          catalog: showModal?.Data?.catalog,
          cs: showModal?.Data?.cs,
          ce: showModal?.Data?.ce,
          iso: showModal?.Data?.iso,
          maf: showModal?.Data?.maf,
          warranty: showModal?.Data?.warranty,
          lead_time: showModal?.Data?.lead_time,
          category: showModal?.Data?.category,
          fator: showModal?.Data?.fator,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("vendor_admin_listing");
      handleClose();
    } else if (showModal?.subtitle === "Delete Product") {
      let param = {
        transmit: "single",
        url: "delete_product",
        request: {
          product_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("vendor_admin_listing");
      handleClose();
    } else if (showModal?.subtitle === "Export Products") {
      const ResultFile =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        showModal?.Data;
      var link = document.createElement("a");
      link.href = ResultFile;
      link.download = "sample.xlsx";
      link.click();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (showModal?.subtitle === "Products Update") {
      const ResultFile =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        showModal?.Data;
      var link = document.createElement("a");
      link.href = ResultFile;
      link.download = "sample.xlsx";
      link.click();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (showModal?.subtitle === "Add Category") {
      if (CategoryName !== "") {
        let param = {
          transmit: "single",
          url: "category_add",
          request: {
            name: CategoryName,
            user_type: selfInfo?.user_type,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        getAllCategories();
        handleClose();
      } else {
        CheckValid(CategoryName, {
          type: "Category Name",
          error: setErrorCategoryName,
        });
      }
    } else if (showModal?.subtitle === "Add Sub Category") {
      if (SubCategoryName !== "" && selectedCategoryId) {
        let param = {
          transmit: "single",
          url: "subcategory_add",
          request: {
            name: SubCategoryName,
            category_id: selectedCategoryId,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("subcategory_listing");
        handleClose();
      } else {
        CheckValid(SubCategoryName, {
          type: "Sub Category Name",
          error: setErrorSubCategoryName,
        });
        CheckValid(ParentCategory, {
          type: "category",
          error: setErrorParentCategory,
        });
      }
    } else if (showModal?.subtitle === "Edit Category") {
      if (CategoryName !== "") {
        let param = {
          transmit: "single",
          url: "category_update",
          request: {
            category_id: showModal?.Data?.id,
            name: CategoryName,
          },
        };
        wsSend_request(websocket, param);
        getAllCategories();
        handleClose();
      } else {
        CheckValid(CategoryName, {
          type: "Category Name",
          error: setErrorCategoryName,
        });
      }
    } else if (showModal?.subtitle === "Edit Sub Category") {
      if (SubCategoryName !== "" && selectedCategoryId) {
        let param = {
          transmit: "single",
          url: "subcategory_update",
          request: {
            sub_category_id: showModal?.Data?.id,
            name: SubCategoryName,
            category_id: selectedCategoryId,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("subcategory_listing");
        handleClose();
      } else {
        CheckValid(SubCategoryName, {
          type: "Sub Category Name",
          error: setErrorSubCategoryName,
        });
        CheckValid(selectedCategoryId, {
          type: "category",
          error: setErrorParentCategory,
        });
      }
    } else if (showModal?.subtitle === "Active Category") {
      let param = {
        transmit: "single",
        url: "category_update",
        request: {
          category_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          name: showModal?.Data?.name,
        },
      };
      wsSend_request(websocket, param);
      getAllCategories();
      handleClose();
    } else if (showModal?.subtitle === "Delete Review") {
      if (ReviewMessage !== "") {
        let param = {
          transmit: "single",
          url: "contact_us",
          request: {
            type: "vendor",
            message: ReviewMessage,
            user_code: showModal?.Data?.usercode,
            vendor_code: showModal?.Data?.vendor_code || selfInfo?.vendor_code,
            email: selfInfo?.business_email_id || selfInfo?.owner_email_id,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        CommonListUpdate("review_listing");
        handleClose();
      } else {
        CheckValid(ReviewMessage, {
          type: "Message",
          error: setErrorReviewMessage,
        });
      }
    } else if (showModal?.subtitle === "Delete Image") {
      if (ReviewMessage !== "") {
        let param = {
          transmit: "single",
          url: "contact_us",
          request: {
            type: "vendor",
            message: ReviewMessage,
            user_code: showModal?.Data?.usercode,
            vendor_code: showModal?.Data?.vendor_code || selfInfo?.vendor_code,
            email: selfInfo?.business_email_id || selfInfo?.owner_email_id,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        CommonListUpdate("vendor_gallery_listing");
        handleClose();
      } else {
        CheckValid(ReviewMessage, {
          type: "Message",
          error: setErrorReviewMessage,
        });
      }
    } else if (showModal?.title === "Raise Ticket") {
      if (ReviewMessage !== "") {
        let param = {
          transmit: "single",
          url: "contact_us",
          request: {
            type: selfInfo?.user_type,
            message: ReviewMessage,
            // user_code: showModal?.Data?.usercode,
            vendor_code: showModal?.Data?.vendor_code || selfInfo?.vendor_code,
            username:
              showModal?.Data?.business_name ||
              selfInfo?.business_contact_person ||
              selfInfo?.owner_contact_person ||
              selfInfo?.business_name,
            email: selfInfo?.business_email_id || selfInfo?.owner_email_id,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        // if (showModal?.subtitle === "Share Coin") {
        //   CommonListUpdate("vendor_coin_transaction");
        // } else {
        //   CommonListUpdate("vendor_payment");
        // }
        handleClose();
      } else {
        CheckValid(ReviewMessage, {
          type: "Message",
          error: setErrorReviewMessage,
        });
      }
    } else if (showModal?.subtitle === "Decline Offer") {
      let param = {
        transmit: "single",
        url: "vendor_payment",
        request: {
          action: "decline",
          particular_id: showModal?.Data?.id,
          particular_type: "offer",
          total_amount: showModal?.total_amount,
        },
      };
      console.log("param", param);
      wsSend_request(websocket, param);
      CommonListUpdate("offer_listing");
      handleClose();
    } else if (showModal?.subtitle === "Decline Event") {
      let param = {
        transmit: "single",
        url: "vendor_payment",
        request: {
          action: "decline",
          particular_id: showModal?.Data?.id,
          particular_type: "event",
          total_amount: showModal?.total_amount,
        },
      };
      console.log("param", param);
      wsSend_request(websocket, param);
      CommonListUpdate("event_listing");
      handleClose();
    } else if (showModal?.subtitle === "Active User Category") {
      let param = {
        transmit: "single",
        url: "category_update",
        request: {
          category_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          name: showModal?.Data?.name,
          type: showModal?.Data?.type,
        },
      };
      wsSend_request(websocket, param);
      getAllCategories();
      handleClose();
    } else if (showModal?.subtitle === "Live User Category") {
      let param = {
        transmit: "single",
        url: "category_update",
        request: {
          category_id: showModal?.Data?.id,
          name: showModal?.Data?.name,
          type: "admin",
        },
      };
      wsSend_request(websocket, param);
      getAllUserCategories();
      handleClose();
    } else if (showModal?.subtitle === "Active Sub Category") {
      let param = {
        transmit: "single",
        url: "subcategory_update",
        request: {
          sub_category_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          name: showModal?.Data?.name,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("subcategory_listing");
      handleClose();
    } else if (showModal?.subtitle === "Delete Category") {
      let param = {
        transmit: "single",
        url: "category_delete",
        request: {
          category_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      getAllCategories();
      handleClose();
    } else if (showModal?.subtitle === "Delete User Category") {
      let param = {
        transmit: "single",
        url: "category_delete",
        request: {
          category_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      getAllUserCategories();
      handleClose();
    } else if (showModal?.subtitle === "Delete Sub Category") {
      let param = {
        transmit: "single",
        url: "subcategory_delete",
        request: {
          sub_category_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("subcategory_listing");
      handleClose();
    } else if (showModal?.subtitle === "Decline Approval") {
      let param = {
        transmit: "single",
        url: "approval_action",
        request: {
          id: showModal?.Data?.id,
          action: "decline",
          type: showModal?.type,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("approval_listing");
      handleClose();
    } else if (showModal?.subtitle === "Approval Success") {
      let param = {
        transmit: "single",
        url: "approval_action",
        request: {
          id: showModal?.Data?.id,
          action: "approve ",
          type: showModal?.type,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("approval_listing");
      handleClose();
    } else if (showModal?.subtitle === "Reply") {
      if (
        SupportEnqTitle !== "" &&
        (StoreDesc?.length !== 0 || StoreDesc?.length !== 49)
      ) {
        let param = {
          transmit: "single",
          url: "contact_us_replay",
          request: {
            inquiry_title: SupportEnqTitle,
            contact_id: showModal?.Data?.id,
            message: StoreDesc,
            email: showModal?.Data?.email,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("contact_us_listing");
        setErrorDesc("");
        handleClose();
      } else {
        CheckValid(SupportEnqTitle, {
          type: "Title",
          error: setErrorSupportEnqTitle,
        });
        if (StoreDesc?.length === 0 || StoreDesc?.length === 49) {
          setErrorDesc("Please enter message");
        }
      }
    } else if (showModal?.subtitle === "Add Field") {
      if (SettingName !== "") {
        let param = {
          transmit: "single",
          url: "add_product_setting",
          request: {
            name: SettingName,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("product_settings_list");
        handleClose();
      } else {
        CheckValid(SettingName, {
          type: "Setting Name",
          error: setErrorSettingName,
        });
      }
    } else if (showModal?.subtitle === "Delete Field") {
      let param = {
        transmit: "single",
        url: "delete_product_setting",
        request: {
          setting_id: showModal?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("product_settings_list");
      handleClose();
    } else if (showModal?.subtitle === "Edit Field") {
      let param = {
        transmit: "single",
        url: "update_product_setting",
        request: {
          setting_id: showModal?.Data?.id,
          name: SettingName,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("product_settings_list");
      handleClose();
    } else if (showModal?.subtitle === "Logout") {
      if (firebaseData) {
        let param = {
          transmit: "single",
          url: "notification_subscription",
          request: {
            topic: selfInfo?.id,
            token: firebaseData,
            type: "unsubscribe",
          },
        };
        wsSend_request(websocket, param);
      }
      CommonLogout(accessToken, Dispatch, firebaseData, selfInfo);
    }
  };

  const FailProductFunction = () => {
    const ResultFile =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      showModal?.Data?.failed;
    var link = document.createElement("a");
    link.href = ResultFile;
    link.download = "sample.xlsx";
    link.click();
    // setTimeout(() => {
    //     handleClose();
    // }, 1000);
  };

  const UpdatedProductFunction = () => {
    const ResultFile =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      showModal?.Data?.updated;
    var link = document.createElement("a");
    link.href = ResultFile;
    link.download = "sample.xlsx";
    link.click();
    // setTimeout(() => {
    //     handleClose();
    // }, 1000);
  };

  // When editing API calling, at that time list API calling.
  const CommonListUpdate = (url) => {
    if (
      showModal?.subtitle === "Create Model" ||
      showModal?.subtitle === "Create Brand" ||
      showModal?.subtitle === "Add Category" ||
      showModal?.subtitle === "Create Supplier"
    ) {
      if (fromUrl === "product") {
        let param = {
          transmit: "single",
          url: url,
          request: {
            filter: "all",
            limit: 5000,
            page: sessionStorage.getItem("currentPage") || 1,
            search: "",
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      } else {
        let param = {
          transmit: "single",
          url: url,
          request: {
            filter: "all",
            limit: 10,
            page: 1,
            search: "",
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      }
    } else if (
      showModal?.subtitle === "Live User Category" ||
      showModal?.subtitle === "Active User Category"
    ) {
      let param = {
        transmit: "single",
        url: url,
        request: {
          filter: "all",
          limit: 10,
          page: 1,
          search: "",
          type: "user",
          category_by: "user",
        },
      };
      wsSend_request(websocket, param);
    } else if (
      showModal?.subtitle === "Decline Approval" ||
      showModal?.subtitle === "Approval Success"
    ) {
      let param = {
        transmit: "single",
        url: "approval_listing",
        request: {
          limit: 10,
          page: 1,
          type: showModal?.type,
        },
      };
      wsSend_request(websocket, param);
    } else {
      let param = {
        transmit: "single",
        url: url,
        request: {
          filter: "all",
          limit: 10,
          page: 1,
          search: "",
          user_type: selfInfo?.user_type,
        },
      };
      wsSend_request(websocket, param);
      if (fromUrl !== "product") {
        setCurrentPage(1);
      }
    }
  };
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");
  console.log("StoreDesc", StoreDesc);
  const onEditorChange = (newEditorState) => {
    setQueEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);
    const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

    setStoreDesc(styledHtmlContent);
  };
  return (
    <Modal
      className={
        showModal?.title === "Create Supplier"
          ? "commonModal addSupplierModel"
          : "commonModal"
      }
      show={showModal?.open}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ width: showModal?.title === "Are you sure?" ? "100%" : "" }}
        >
          {showModal?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showModal?.modalType === "form" ? (
          <Form.Group className="mb-3">
            {showModal?.title === "Earnings" ||
            showModal?.title === "Edit Earning" ? (
              <>
                <InputField
                  type="text"
                  value={EarningReason}
                  className={
                    errorEarningReason !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Please Enter Reason"
                  label="Reason <span class='redmednstar'>*</span>"
                  name="EarningReason"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "EarningReason",
                      error: setErrorEarningReason,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorEarningReason}
                  readOnly={true}
                />
                <InputField
                  type="number"
                  value={EarningCoin}
                  className={
                    errorEarningCoin !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Please Enter Coin"
                  label="Coin <span class='redmednstar'>*</span>"
                  name="EarningCoin"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "EarningCoin",
                      error: setErrorEarningCoin,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorEarningCoin}
                  required={true}
                />
              </>
            ) : showModal?.title === "Add Badge" ||
              showModal?.title === "Edit Badge" ? (
              <>
                <InputField
                  type="text"
                  value={BadgeLevel}
                  className={
                    errorBadgeLevel !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Please Enter Level"
                  label="Level <span class='redmednstar'>*</span>"
                  name="BadgeLevel"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "BadgeLevel",
                      error: setErrorBadgeLevel,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorBadgeLevel}
                  required={true}
                />
                <InputField
                  type="number"
                  value={BadgePoints}
                  className={
                    errorBadgePoints !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Please Enter Points"
                  label="Points <span class='redmednstar'>*</span>"
                  name="BadgePoints"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "BadgePoints",
                      error: setErrorBadgePoints,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorBadgePoints}
                  required={true}
                />

                <div class="item-wrapper one">
                  <div class="item">
                    {!AvatarImage ? (
                      <form
                        data-validation="true"
                        action="#"
                        method="post"
                        encType="multipart/form-data"
                      >
                        <div className="item-inner">
                          <div className="item-content">
                            <div className="image-upload">
                              <label
                                className="cursor-pointer"
                                htmlFor="file_upload"
                              >
                                <img src="" alt="" className="uploaded-image" />
                                <div className="h-100">
                                  <div className="dplay-tbl">
                                    <div className="dplay-tbl-cell">
                                      <i className="bi bi-cloud-arrow-up"></i>{" "}
                                      <h5>
                                        <b>Choose Your Image to Upload</b>
                                      </h5>
                                      <h6 className="mt-10 mb-70">
                                        Or Drop Your Image Here
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <input
                                  data-required="image"
                                  type="file"
                                  name="image_name"
                                  id="file_upload"
                                  className="image-input"
                                  data-traget-resolution="image_resolution"
                                  value=""
                                  accept=".jpg, .jpeg, .png"
                                  onChange={UploadImage}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <div>
                        <div className="text-end" onClick={RemoveUploadImage}>
                          <img
                            src={closeIcon}
                            height={"20px"}
                            width={"20px"}
                            className="ms-5"
                            alt=""
                          />
                        </div>
                        <div className="text-center">
                          <MediaViewer
                            media={
                              AvatarImage?.url?.file_url
                                ? AvatarImage?.url?.file_url
                                : AvatarImage?.url
                                ? AvatarImage?.url
                                : ""
                            }
                            minHeight={"100px"}
                            minWidth={"100px"}
                            maxHeight={"200px"}
                            maxWidth={"200px"}
                          />
                          {console.log("AvatarImage", AvatarImage)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : showModal?.title === "Add Category" ||
              showModal?.title === "Edit Category" ? (
              <InputField
                type="text"
                value={CategoryName}
                className={
                  errorCategoryName !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter Category Name"
                label="Category Name <span class='redmednstar'>*</span>"
                name="CategoryName"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Category Name",
                    error: setErrorCategoryName,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorCategoryName}
                required={true}
                maxLength={100}
              />
            ) : showModal?.title === "Add Sub Category" ||
              showModal?.title === "Edit Sub Category" ? (
              <>
                <InputField
                  type="text"
                  value={SubCategoryName}
                  className={
                    errorSubCategoryName !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Enter Sub Category Name"
                  label="Sub Category Name <span class='redmednstar'>*</span>"
                  name="SubCategoryName"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Sub Category Name",
                      error: setErrorSubCategoryName,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorSubCategoryName}
                  required={true}
                  maxLength={100}
                />
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Select Category <span className="common-blue">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={ParentCategory}
                      onChange={(e) => {
                        setParentCategory(e.target.value);
                      }}
                      required
                      onKeyUp={(e) =>
                        CheckValid(ParentCategory, {
                          type: "category",
                          error: setErrorParentCategory,
                        })
                      }
                    >
                      <option value="" selected disabled>
                        Select Category
                      </option>

                      {categoryList?.list &&
                        categoryList?.list.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>

                    {errorParentCategory && (
                      <div className="errormendatorr">
                        <i className="bi bi-x-circle-fill"></i>
                        {errorParentCategory}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : showModal?.subtitle === "Delete Review" ? (
              <>
                <InputField
                  type="text"
                  value={showModal?.Data?.usercode}
                  className={"form-control"}
                  placeholder="Enter User Code"
                  label="User Code <span class='redmednstar'>*</span>"
                  errormessage={""}
                  readOnly={true}
                />

                <InputField
                  type="text"
                  value={ReviewMessage}
                  className={
                    errorReviewMessage !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  name={"ReviewMessage"}
                  placeholder="Type your message.."
                  label="Message <span class='redmednstar'>*</span>"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Message",
                      error: setErrorReviewMessage,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorReviewMessage}
                  required={true}
                />
              </>
            ) : showModal?.subtitle === "Delete Image" ? (
              <div>
                <div className="bg-blue w-100">
                  <p className="info px-2 py-2">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2187_37685)">
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                          fill="#407BFF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2187_37685">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="common-blue">
                      Request the deletion of a media.
                    </span>
                  </p>
                </div>
                <InputField
                  type="text"
                  value={showModal?.Data?.usercode}
                  className={"form-control"}
                  placeholder="Enter User Code"
                  label="User Code <span class='redmednstar'>*</span>"
                  errormessage={""}
                  readOnly={true}
                />

                <InputField
                  type="text"
                  value={ReviewMessage}
                  className={
                    errorReviewMessage !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  name="ReviewMessage"
                  placeholder="Type your message.."
                  label="Message <span class='redmednstar'>*</span>"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Message",
                      error: setErrorReviewMessage,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorReviewMessage}
                  required={true}
                />
              </div>
            ) : showModal?.subtitle === "Share Coin" ||
              showModal?.subtitle === "Share Payment" ? (
              <>
                <div className="bg-blue w-100">
                  <p className="info px-2 py-2">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2187_37685)">
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                          fill="#407BFF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2187_37685">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="common-blue">
                      Please provide the transaction ID for better
                      understanding.
                    </span>
                  </p>
                </div>
                <InputField
                  type="text"
                  value={selfInfo?.business_name}
                  className={"form-control"}
                  placeholder="Enter Business Name"
                  label="Business Name <span class='redmednstar'>*</span>"
                  errormessage={""}
                  readOnly={true}
                />
                <InputField
                  type="text"
                  value={
                    selfInfo?.business_email_id || selfInfo?.owner_email_id
                  }
                  className={"form-control"}
                  placeholder="Enter Email Address"
                  label="Email Address <span class='redmednstar'>*</span>"
                  errormessage={""}
                  readOnly={true}
                />

                <InputField
                  type="text"
                  value={ReviewMessage}
                  className={
                    errorReviewMessage !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  name={"ReviewMessage"}
                  placeholder="Type your message.."
                  label="Message <span class='redmednstar'>*</span>"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Message",
                      error: setErrorReviewMessage,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorReviewMessage}
                  required={true}
                />
              </>
            ) : showModal?.title === "Add Field" ||
              showModal?.title === "Edit Field" ? (
              <InputField
                type="text"
                value={SettingName}
                className={
                  errorSettingName !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter Field Name"
                label="Field Name <span class='redmednstar'>*</span>"
                name="SettingName"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Setting Name",
                    error: setErrorSettingName,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorSettingName}
                required={true}
              />
            ) : showModal?.title === "Reply" ||
              showModal?.title === "Edit Reply" ? (
              <>
                <div className="w-100">
                  <InputField
                    type="text"
                    value={SupportEnqTitle}
                    className={
                      errorSupportEnqTitle !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enquiry Title"
                    label="Enquiry Title <span class='redmednstar'>*</span>"
                    name="SupportEnqTitle"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "SupportEnqTitle",
                        error: setErrorSupportEnqTitle,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorSupportEnqTitle}
                    required={true}
                    maxLength={100}
                  />
                </div>
                <div className="w-95">
                  <label htmlFor="toolbarClassName" className="mb-2 fs-14px">
                    Message <span className="common-blue">*</span>
                  </label>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: "100%",
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                      fontFamily: "sans-serif",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                  {errorDesc !== "" && (
                    <div className="errormendatorr">
                      <i className="bi bi-x-circle-fill"></i>
                      {errorDesc}
                    </div>
                  )}
                </div>
              </>
            ) : showModal?.title === "Create Model" ||
              showModal?.title === "Edit Model" ? (
              <>
                <InputField
                  type="text"
                  value={ModelName}
                  className={
                    errorModelName !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Enter Model Name"
                  label="Model Name <span class='redmednstar'>*</span>"
                  name="ModelName"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Model Name",
                      error: setErrorModelName,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorModelName}
                  required={true}
                />
                <div className="form-group">
                  <label>
                    Brand List <span className="redmednstar">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={selectBrand}
                    onChange={(e) => selectBrandChange(e)}
                  >
                    <option value="">Select Brand</option>
                    {brandlistall?.map((elm, index) => {
                      return (
                        <option value={elm?.id} key={index}>
                          {elm?.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorSelectBrand !== "" && (
                    <div className="errormendatorr">
                      <i className="bi bi-x-circle-fill"></i>
                      {errorSelectBrand}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <AddEditSupplier
                handleClose={handleClose}
                AddSupplierForPorduct={"AddSupplierForPorduct"}
              />
            )}
          </Form.Group>
        ) : (
          <>
            <div className="notformsection">
              {showModal?.subtitle === "Logout" && (
                <>
                  <img src={fileIcon} className="logoutIconbtn" alt="" />
                  <h3>Log Out</h3>
                </>
              )}
              <p
                dangerouslySetInnerHTML={{ __html: showModal?.description }}
              ></p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center gap-3 align-items-center w-100">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        {showModal?.subtitle === "Products Update and Export" ? (
          <React.Fragment>
            <Button
              variant="primary"
              className="addbtncmn"
              style={{ padding: "7px 15px" }}
              onClick={FailProductFunction}
            >
              {loader && <BtnLoader />}
              {showModal?.button} Failed Product
            </Button>
            <Button
              variant="primary"
              className="addbtncmn"
              style={{ padding: "7px 15px" }}
              onClick={UpdatedProductFunction}
            >
              {loader && <BtnLoader />}
              {showModal?.button} Updated Product
            </Button>
          </React.Fragment>
        ) : (
          <Button
            variant="primary"
            className="addbtncmn"
            onClick={CommonFunction}
          >
            {loader && <BtnLoader />}
            {showModal?.button}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModel;
